import { useDispatch, useSelector } from "react-redux";
import { ResponsiveWidthProfile } from "../app/common";
import { AppDispatch, RootState } from "../app/store";
import { ProfileInfo } from "../features/Accounts/Pages/profileInfo";
import { Directory } from "../features/Directories/Pages/directory";
import { Membership } from "../features/Directories/Pages/membership";
import { Licenses } from "../features/Licenses/Pages/licenses";
import { Subscription } from "../features/stripe/Pages/subscription";
import { useEffect, useState } from "react";
import { GetAllDirectories, GetDirectoryInfoData, GetDirectoryMembers, GetHomeDirectory, } from "../features/Directories/directorySlice";
import { GetCustomerSubscriptions, GetSubscriptionSchedules, resetSchedules, } from "../features/stripe/stripeSlice";
import { GetViewLicensesByUser } from "../features/Licenses/licensesSlice";

import { CurrentProducPrice, GetSubscriptionSchedulesRequest, Product, } from "../features/stripe/stripeModel";
import { GetUser } from "../features/Accounts/accountSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CompanyInfo } from "../features/Portal/Pages/companyInfo";
import { GetProfileCompanies } from "../features/Portal/PortalSlice";
import { AdminCompanies } from "../features/Portal/Pages/adminCompanies";
import { PortalCompany } from "../features/Portal/PortalModel";

export interface SubTierUpdate {
  subId: string;
  availableProductsTierUpdate: Product[];
  availablePricesTierUpdate: CurrentProducPrice[];
  availablePricesIntervalUpdate: CurrentProducPrice[];
}

export function ProfilePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const accountSliceData = useSelector((state: RootState) => state.AccountSlice);
  const directorySliceData = useSelector((state: RootState) => state.DirectorySlice);
  const stripeSliceData = useSelector((state: RootState) => state.StripeSlice);
  const licensesSliceData = useSelector((state: RootState) => state.LicensesSlice);
  const portalSliceData = useSelector((state: RootState) => state.PortalSlice);
  const navigate = useNavigate();
  const [companiesForCreateItems, setCompaniesForCreateItems] = useState<PortalCompany[]>([])

  useEffect(() => {
    const combinedCompanies = [
      ...portalSliceData.portalSliceData.profileCompanies.adminCompanies,
      ...portalSliceData.portalSliceData.profileCompanies.ownerCompanies,
    ];

    setCompaniesForCreateItems(combinedCompanies);
  }, [portalSliceData.portalSliceData.profileCompanies])

  useEffect(() => {
    let token = localStorage.getItem("webShopBimTAccessToken")
    if (token === undefined || token === null) {
      navigate('/');
    }
    else {
      dispatch(GetUser());
      dispatch(GetHomeDirectory());
      dispatch(GetAllDirectories());
      dispatch(GetCustomerSubscriptions());
      dispatch(GetViewLicensesByUser());
      dispatch(GetProfileCompanies())
    }
  }, []);

  //GetSubscriptionSchedules
  useEffect(() => {
    let subscriptionScheduleIds: GetSubscriptionSchedulesRequest = {
      subscriptionScheduleIds: []
    };
    stripeSliceData.stripeSliceData.stripeData.customerSubscriptions.subscriptions.forEach((sub) => {
      if (sub.subscriptionScheduleId !== null) {
        subscriptionScheduleIds.subscriptionScheduleIds.push(sub.subscriptionScheduleId)
      }
    });

    if (subscriptionScheduleIds.subscriptionScheduleIds.length !== 0)
      dispatch(GetSubscriptionSchedules(subscriptionScheduleIds))
    else {
      dispatch(resetSchedules())
    }

  }, [stripeSliceData.stripeSliceData.stripeData.customerSubscriptions.subscriptions]);

  useEffect(() => {
    if (directorySliceData.directorySliceData.directoryData.homeDirectoryData.directoryId !== "") {
      dispatch(GetDirectoryInfoData(directorySliceData.directorySliceData.directoryData.homeDirectoryData));
      dispatch(GetDirectoryMembers(directorySliceData.directorySliceData.directoryData.homeDirectoryData));
    }
  }, [directorySliceData.directorySliceData.directoryData.homeDirectoryData]);

  return (
    <div>
      <div className={"bg-#F6F6F6 h-[60px] xl:2xl:h-[70px] flex justify-center items-center w-full"}>
        <h1 className="uppercase text-#333333 font-bold sm:text-[18px] lg:text-[20px] xl:text-[24px] 2xl:text-[36px]">
          {t("MyProfile")}
        </h1>
      </div>
      <div className={"space-y-10 pb-12 2xl:px-32 xl:px-28 lg:px-20 sm:px-16 px-12 " + ResponsiveWidthProfile}>
        <ProfileInfo accountData={accountSliceData.accountSliceData.accountData} statusValues={accountSliceData.accountSliceData.statusValues} />
        {portalSliceData.portalSliceData.profileCompanies.hasPortalLicense && <CompanyInfo />}
        {companiesForCreateItems.length > 0 && <AdminCompanies />}
        <Membership
          directoryData={directorySliceData.directorySliceData.directoryData}
          statusValues={directorySliceData.directorySliceData.statusValues}
        />
        <Directory
          directoryData={directorySliceData.directorySliceData.directoryData}
          statusValues={directorySliceData.directorySliceData.statusValues}
        />
        <Subscription
          stripeData={stripeSliceData.stripeSliceData.stripeData}
          statusValues={stripeSliceData.stripeSliceData.statusValues}
          subscriptionSchedules={stripeSliceData.stripeSliceData.stripeData.subscriptionSchedules.subscriptionScheduleResponses}
          updateValues={stripeSliceData.stripeSliceData.updateValues} />
        <Licenses
          directorySliceData={{ directoryData: directorySliceData.directorySliceData.directoryData, statusValues: directorySliceData.directorySliceData.statusValues }}
          stripeSliceData={{ stripeData: stripeSliceData.stripeSliceData.stripeData, statusValues: stripeSliceData.stripeSliceData.statusValues, updateValues: stripeSliceData.stripeSliceData.updateValues }}
          licensesStripeData={{ licensesData: licensesSliceData.licensesSliceData.licensesData, statusValues: licensesSliceData.licensesSliceData.statusValues }}
        />
      </div>
    </div>
  );
}
