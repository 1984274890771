import { useEffect, useState } from "react";
import { isValidBase64Image } from "../../app/common";
import { PortalItem } from "../../features/Portal/PortalModel";
import BrokenImage from "../../Images/BrokenImage.png";

type PortalCardProps =
    | {
        portalItem: PortalItem;
        isAdmin: false;
    }
    | {
        portalItem: PortalItem;
        isAdmin: true;
        onEditClick: (id: string) => void;
        onDeleteClick: (id: string) => void;
    };

function PortalCard(props: PortalCardProps) {
    const [isHovered, setIsHovered] = useState(false);

    const handleEditClick = () => {
        if (props.isAdmin) {
            props.onEditClick(props.portalItem.id);
        }
    };

    const handleDeleteClick = () => {
        if (props.isAdmin) {
            props.onDeleteClick(props.portalItem.id);
        }
    };

    return (
        <div className="w-[220px] md:w-[397px]">
            {/* Photo */}
            <div className={`relative border border-#C5C5C5-50 rounded-[4px] h-[220px] md:h-[397px] flex items-center justify-center`}
                onMouseEnter={() => props.isAdmin && setIsHovered(true)} onMouseLeave={() => props.isAdmin && setIsHovered(false)}>
                <img src={isValidBase64Image(props.portalItem.photo) ? props.portalItem.photo! : BrokenImage} className={`p-[6px] md:p-[12px] h-max w-max ${isHovered ? "opacity-20" : ""}`} alt="" />
                <div className="absolute bottom-[6px] right-[6px] md:bottom-[12px] md:right-[12px] min-w-[70px] h-[20px] md:min-w-[130px] md:h-[40px] bg-white flex items-center justify-center text-#4D4D4D font-medium">
                    <span className="text-center text-xs md:text-lg px-2">{props.portalItem.price} {props.portalItem.currency}/{props.portalItem.unit}</span>
                </div>

                {isHovered && (
                    <div className="absolute inset-0 flex items-center justify-center gap-4">
                        <button onClick={handleEditClick}>
                            {/* Edit Icon */}
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#717171 hover:stroke-#FF9800 cursor-pointer">
                                <path d="M12.1241 2.87079L12.9583 2.0366C14.3404 0.654467 16.5813 0.654467 17.9634 2.0366C19.3455 3.41873 19.3455 5.65962 17.9634 7.04175L17.1292 7.87594M12.1241 2.87079C12.1241 2.87079 12.2283 4.64345 13.7924 6.20756C15.3566 7.77167 17.1292 7.87594 17.1292 7.87594M12.1241 2.87079L4.45494 10.5399C3.9355 11.0594 3.67577 11.3191 3.45241 11.6055C3.18892 11.9433 2.96302 12.3088 2.77871 12.6955C2.62246 13.0234 2.50631 13.3718 2.274 14.0687L1.28963 17.0219M17.1292 7.87594L9.46009 15.5451C8.94065 16.0645 8.68092 16.3242 8.39455 16.5476C8.05674 16.8111 7.69122 17.037 7.30448 17.2213C6.97663 17.3775 6.62817 17.4937 5.93126 17.726L2.97813 18.7104M2.97813 18.7104L2.25626 18.951C1.9133 19.0653 1.53519 18.9761 1.27957 18.7204C1.02394 18.4648 0.934685 18.0867 1.049 17.7437L1.28963 17.0219M2.97813 18.7104L1.28963 17.0219" strokeWidth="1.3" />
                            </svg>
                        </button>

                        <button onClick={handleDeleteClick}>
                            {/* Delete Icon */}
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="fill-#717171 hover:fill-#FF9800 cursor-pointer">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.55936 1.89484e-05H10.9408C11.1572 -0.000119096 11.3457 -0.000239318 11.5237 0.0281879C12.227 0.140494 12.8356 0.579147 13.1646 1.21084C13.2478 1.37073 13.3073 1.54961 13.3756 1.75494L13.4873 2.08984C13.5062 2.14653 13.5116 2.16258 13.5161 2.17522C13.6913 2.65933 14.1453 2.98659 14.6599 2.99964C14.6735 2.99998 14.69 3.00004 14.7501 3.00004H17.7501C18.1643 3.00004 18.5001 3.33582 18.5001 3.75004C18.5001 4.16425 18.1643 4.50004 17.7501 4.50004H0.75C0.335786 4.50004 0 4.16425 0 3.75004C0 3.33582 0.335786 3.00004 0.75 3.00004H3.75008C3.81013 3.00004 3.8267 2.99998 3.84023 2.99964C4.35488 2.98659 4.80891 2.65936 4.98402 2.17524C4.98863 2.16251 4.99392 2.14681 5.01291 2.08984L5.12452 1.75496C5.19281 1.54964 5.25233 1.37073 5.33559 1.21084C5.66453 0.579149 6.27313 0.140494 6.97643 0.0281879C7.15445 -0.000239318 7.34297 -0.000119096 7.55936 1.89484e-05ZM6.25815 3.00004C6.30966 2.89902 6.35531 2.79404 6.39458 2.68548C6.4065 2.65251 6.4182 2.61742 6.43322 2.57234L6.53302 2.27292C6.62419 1.99941 6.64519 1.94363 6.66601 1.90364C6.77566 1.69307 6.97853 1.54686 7.21296 1.50942C7.25748 1.50231 7.31703 1.50004 7.60535 1.50004H10.8948C11.1831 1.50004 11.2427 1.50231 11.2872 1.50942C11.5216 1.54686 11.7245 1.69307 11.8342 1.90364C11.855 1.94363 11.876 1.9994 11.9671 2.27292L12.0669 2.57216L12.1056 2.6855C12.1449 2.79405 12.1905 2.89902 12.242 3.00004H6.25815Z" />
                                <path d="M3.16509 6.20015C3.13754 5.78685 2.78016 5.47415 2.36686 5.5017C1.95357 5.52925 1.64086 5.88663 1.66841 6.29993L2.13186 13.2517C2.21736 14.5344 2.28642 15.5705 2.44839 16.3836C2.61679 17.2289 2.90321 17.935 3.4948 18.4885C4.0864 19.0419 4.80995 19.2808 5.6646 19.3925C6.48662 19.5001 7.52504 19.5001 8.8106 19.5H9.6895C10.9751 19.5001 12.0135 19.5001 12.8356 19.3925C13.6902 19.2808 14.4138 19.0419 15.0054 18.4885C15.597 17.935 15.8834 17.2289 16.0518 16.3836C16.2138 15.5706 16.2828 14.5344 16.3683 13.2517L16.8318 6.29993C16.8593 5.88663 16.5466 5.52925 16.1333 5.5017C15.72 5.47415 15.3626 5.78685 15.3351 6.20015L14.8751 13.0993C14.7853 14.4471 14.7213 15.3849 14.5807 16.0906C14.4443 16.775 14.254 17.1373 13.9806 17.3931C13.7072 17.6489 13.333 17.8147 12.641 17.9052C11.9276 17.9985 10.9876 18 9.63676 18H8.8634C7.51256 18 6.57255 17.9985 5.85915 17.9052C5.16715 17.8147 4.79299 17.6489 4.51958 17.3931C4.24617 17.1373 4.05583 16.775 3.91948 16.0906C3.77892 15.3849 3.71489 14.4471 3.62503 13.0993L3.16509 6.20015Z" />
                                <path d="M6.67546 8.00376C7.08762 7.96254 7.45515 8.26325 7.49637 8.67541L7.99637 13.6754C8.03759 14.0876 7.73688 14.4551 7.32472 14.4963C6.91256 14.5375 6.54503 14.2368 6.50381 13.8247L6.00381 8.82467C5.9626 8.41251 6.26331 8.04498 6.67546 8.00376Z" />
                                <path d="M11.8247 8.00376C12.2369 8.04498 12.5376 8.41251 12.4964 8.82467L11.9964 13.8247C11.9552 14.2368 11.5876 14.5375 11.1755 14.4963C10.7633 14.4551 10.4626 14.0876 10.5038 13.6754L11.0038 8.67541C11.045 8.26325 11.4126 7.96254 11.8247 8.00376Z" />
                            </svg>
                        </button>
                    </div>
                )}
            </div>
            {/* Body */}
            <div>
                <div className="flex-col p-[12px]">
                    <div><span className="font-medium text-lg">{props.portalItem.title}</span> - <span className="text-base font-light">{props.portalItem.code}</span></div>
                    <div className="italic text-sm 2xl:text-base font-extralight">{props.portalItem.type}</div>
                </div>
                <div className="h-[1px] w-auto bg-#C5C5C5 rounded-[5px]" />
                <div className="p-[12px] h-[110px]" dangerouslySetInnerHTML={{ __html: props.portalItem.description }} />
            </div>
        </div>);
}

export default PortalCard;