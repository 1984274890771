import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import Pagination from "../UIComponents/Pagination/Pagination";
import { useEffect, useState } from "react";
import { PortalCompany } from "../features/Portal/PortalModel";
import BrokenImage from "../Images/BrokenImage.png";
import { Link, Navigate } from "react-router-dom";
import { GetAllCompanies, GetCompaniesByUser, resetPortalSlice } from "../features/Portal/PortalSlice";
import { LoadingPage } from "../features/CommonPages/loadingPage";
import { isValidBase64Image } from "../app/common";

function PortalPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const portalData = useSelector((state: RootState) => state.PortalSlice);
    const [companies, setComapnies] = useState<PortalCompany[] | []>([])

    useEffect(() => {
        dispatch(resetPortalSlice())
        dispatch(GetAllCompanies())
    }, [])

    useEffect(() => {
        if (portalData.portalSliceData.companies) {
            const publicCompanies = portalData.portalSliceData.companies.filter(company => company.isPublic);
            setComapnies(publicCompanies);
        }
    }, [portalData.portalSliceData.companies]);

    if (companies.length === 1) {
        return <Navigate to={`/portal/${companies[0].name}`} replace />;
    }

    const CompanyCard = ({ companyItem }: { companyItem: PortalCompany }) => (
        <div className="w-[220px] md:w-[397px] cursor-pointer mb-10">
            {/* Photo */}
            <div className="relative border border-#C5C5C5-50 rounded-[4px] h-[220px] md:h-[397px] flex items-center justify-center">
                <img src={isValidBase64Image(companyItem.photo) ? companyItem.photo! : BrokenImage} className="p-[6px] md:p-[12px] h-max w-max" alt={companyItem.name} />
            </div>
            {/* Body */}
            <div className="flex-col p-[12px] text-center">
                <div className="font-medium text-lg">{companyItem.name}</div>
            </div>
        </div>
    );

    const renderCompanies = (companyItem: PortalCompany) => (
        <Link to={`/portal/${companyItem.name}`}>
            <CompanyCard companyItem={companyItem} />
        </Link>
    );

    if (portalData.portalSliceData.statusValues.isLoading) {
        return (LoadingPage(true))
    }

    return (
        <div className="mb-[30px]">
            <div className="bg-gradient-to-b from-#28373D to-#336376 min-h-[182px] px-[30px] lg:px-[100px] flex flex-col sm:flex-row items-center content-start gap-[25px]">
                <div className="text-[26px] text-#FFFFFF font-normal">{t("BrowseAllBrochures")}</div>
            </div>
            {companies.length > 0 ? <Pagination items={companies} renderItem={renderCompanies} itemsLength={companies.length} /> :
                <div className="text-center mt-[50px] text-xl text-#4D4D4D">{t("NoBrochuresFound")}</div>}
        </div>
    );
}

export default PortalPage;