import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { PortalCompany, PortalItem } from "../../features/Portal/PortalModel";
import Pagination from "../Pagination/Pagination";
import PortalCard from "./PortalCard";
import { useEffect, useState } from "react";
import { GetAllCompanies, GetAllPortalItems, resetStatusCode } from "../../features/Portal/PortalSlice";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../features/CommonPages/loadingPage";
import { useParams } from "react-router-dom";
import { isValidBase64Image } from "../../app/common";

function CompanyPortalPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [portalItems, setPortalItems] = useState<PortalItem[]>([]);
    const [companyShortName, setCompanyShortName] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [message, setMesssage] = useState(t("SuccessText"));
    const [success, setSuccess] = useState(false);
    const { companyName } = useParams<{ companyName: string }>();
    const [companyToShow, setCompanyToShow] = useState<PortalCompany>()
    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const portalData = useSelector((state: RootState) => state.PortalSlice);

    const renderProduct = (portalItem: PortalItem) => (
        <PortalCard key={portalItem.id} portalItem={portalItem} isAdmin={false} />
    );

    const processString = (str: string): string => {
        const words = str.toUpperCase().trim().split(/\s+/);
        if (words.length === 1) {
            return words[0].substring(0, 2);
        }
        return (words[0][0] || "") + (words[1][0] || "");
    };

    useEffect(() => {
        dispatch(GetAllCompanies())
    }, [])

    useEffect(() => {
        setCompanyToShow(portalData.portalSliceData.companies.find(company => company.name === companyName));
    }, [portalData.portalSliceData.companies])

    useEffect(() => {
        if (companyToShow) {
            setCompanyShortName(processString(companyToShow.name));
            dispatch(GetAllPortalItems(companyToShow.id));
        }
    }, [companyToShow]);

    useEffect(() => {
        setPortalItems(portalData.portalSliceData.portalItemsOfCompany);
    }, [portalData.portalSliceData.portalItemsOfCompany]);

    useEffect(() => {
        if (portalData.portalSliceData.statusValues.statusCode === '200') {
            setSuccess(true);
            handleOpenDialog();
        }
        else if (portalData.portalSliceData.statusValues.statusCode !== '') {
            setMesssage(t("UnsuccessText"));
            setSuccess(false);
            handleOpenDialog();
        }
        dispatch(resetStatusCode());
    }, [portalData.portalSliceData.statusValues.statusCode]);

    function normalizeURL(url: string | null | undefined): string | undefined {
        if (!url) return undefined;

        if (!/^https?:\/\//i.test(url)) {
            url = `http://${url}`;
        }

        try {
            const validatedURL = new URL(url);
            return validatedURL.href;
        } catch (e) {
            console.error("Invalid URL:", url);
            return undefined;
        }
    }

    function getDisplayText(url: string | null | undefined): string {
        if (!url) return "Invalid URL";
        return url.replace(/^https?:\/\//i, "");
    }

    if (!companyToShow || portalData.portalSliceData.statusValues.isLoading) {
        return (LoadingPage(true))
    }
    else {
        return (
            <div className="mb-[30px]">
                <div className="bg-gradient-to-b from-#28373D to-#336376 min-h-[182px] px-[30px] lg:px-[100px] flex flex-col sm:flex-row items-center content-start gap-[25px]">
                    <div className="rounded-full bg-#D9D9D9 w-[123px] h-[123px] min-w-[123px] min-h-[123px] flex items-center justify-center">
                        {isValidBase64Image(companyToShow.photo) ?
                            <img className="w-full h-full object-contain rounded-full" src={companyToShow.photo!} alt="" /> : <label className="text-[50px] font-medium text-#FF9800">{companyShortName}</label>}
                    </div>
                    <div className="flex flex-col xl:flex-row xl:items-center content-start xl:gap-[25px] text-center sm:text-left">
                        <div className="text-[26px] text-#FFFFFF">{companyToShow.name}</div>
                        <div className="hidden xl:block h-[48px] w-[2px] bg-#FFFFFF" />
                        <div className="flex flex-col sm:items-start items-center">
                            <div className="flex items-center text-#F5F5F5">
                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6.7C1 4.01299 1 2.66949 1.83475 1.83475C2.66949 1 4.01299 1 6.7 1H9.55C12.237 1 13.5805 1 14.4153 1.83475C15.25 2.66949 15.25 4.01299 15.25 6.7C15.25 9.38701 15.25 10.7305 14.4153 11.5653C13.5805 12.4 12.237 12.4 9.55 12.4H6.7C4.01299 12.4 2.66949 12.4 1.83475 11.5653C1 10.7305 1 9.38701 1 6.7Z" stroke="#F5F5F5" />
                                    <path d="M3.8501 3.84961L5.38831 5.13145C6.69691 6.22196 7.35122 6.76721 8.1251 6.76721C8.89898 6.76721 9.55328 6.22196 10.8619 5.13145L12.4001 3.84961" stroke="#F5F5F5" strokeLinecap="round" />
                                </svg>
                                <span className="ml-[10px]">{companyToShow.email}</span>
                            </div>
                            {companyToShow.website && <div className="flex items-center text-#F5F5F5">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 8C15 8.91925 14.8189 9.8295 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.8295 14.8189 8.91925 15 8 15C7.08075 15 6.17049 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.8295 1 8.91925 1 8C1 7.08075 1.18106 6.17049 1.53284 5.32122C1.88463 4.47194 2.40024 3.70026 3.05025 3.05025C3.70026 2.40024 4.47194 1.88463 5.32122 1.53284C6.1705 1.18106 7.08075 1 8 1C8.91925 1 9.82951 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.1705 15 7.08075 15 8L15 8Z" stroke="#F5F5F5" />
                                    <path d="M10.8002 8C10.8002 8.91925 10.7278 9.8295 10.5871 10.6788C10.4463 11.5281 10.2401 12.2997 9.98009 12.9497C9.72009 13.5998 9.41142 14.1154 9.07171 14.4672C8.732 14.8189 8.3679 15 8.0002 15C7.63249 15 7.26839 14.8189 6.92868 14.4672C6.58897 14.1154 6.2803 13.5998 6.0203 12.9497C5.76029 12.2997 5.55405 11.5281 5.41333 10.6788C5.27262 9.8295 5.2002 8.91925 5.2002 8C5.2002 7.08075 5.27262 6.17049 5.41333 5.32122C5.55405 4.47194 5.76029 3.70026 6.0203 3.05025C6.2803 2.40024 6.58897 1.88463 6.92868 1.53284C7.26839 1.18106 7.63249 1 8.0002 1C8.3679 1 8.732 1.18106 9.07171 1.53284C9.41142 1.88463 9.72009 2.40024 9.98009 3.05025C10.2401 3.70026 10.4463 4.47194 10.5871 5.32122C10.7278 6.1705 10.8002 7.08075 10.8002 8L10.8002 8Z" stroke="#F5F5F5" />
                                    <path d="M1 8H15" stroke="#F5F5F5" strokeLinecap="round" />
                                </svg>
                                <a href={normalizeURL(companyToShow.website)} className="ml-[10px]">
                                    {getDisplayText(companyToShow.website)}
                                </a>
                            </div>}
                        </div>
                    </div>
                </div>
                {portalItems?.length > 0 ? <Pagination items={portalItems} renderItem={renderProduct} itemsLength={portalItems.length} /> :
                    <div className="text-center mt-[50px] text-xl text-#4D4D4D">{t("NoItemsFound")}</div>}
            </div>
        );
    }
}

export default CompanyPortalPage;
