// companyinfo

import { Typography } from "@material-tailwind/react";
import { isValidBase64Image, ResponsiveWidthProfile } from "../../../app/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PortalCompany } from "../PortalModel";
import BrokenImage from "../../../Images/BrokenImage.png";
import { Link } from "react-router-dom";

export function AdminCompanies() {
    const { t } = useTranslation();
    const portalSliceData = useSelector((state: RootState) => state.PortalSlice.portalSliceData);
    const [companiesForCreateItems, setCompaniesForCreateItems] = useState<PortalCompany[]>([])

    useEffect(() => {
        const combinedCompanies = [
            ...portalSliceData.profileCompanies.adminCompanies,
            ...portalSliceData.profileCompanies.ownerCompanies,
        ];

        setCompaniesForCreateItems(combinedCompanies);
    }, [portalSliceData.profileCompanies])

    const CompanyCard = ({ companyItem }: { companyItem: PortalCompany }) => (
        <Link to={`/portal/createItems/${companyItem.name}`}>
            <div className="w-[120px] md:w-[197px] cursor-pointer">
                {/* Photo */}
                <div className="relative border border-#C5C5C5-50 rounded-[4px] h-[120px] md:h-[197px] flex items-center justify-center">
                    <img src={isValidBase64Image(companyItem.photo) ? companyItem.photo! : BrokenImage} className="p-[6px] md:p-[12px] h-max w-max" alt={companyItem.name} />
                </div>
                {/* Body */}
                <div className="flex-col p-[12px] text-center">
                    <div className="font-medium text-lg">{companyItem.name}</div>
                </div>
            </div>
        </Link>
    );

    return (
        <div id="companyInfo" className={"lg:scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[90px] rounded-b-[5px] bg-#FFFFFF h-auto space-y-5 shadow-4s " + ResponsiveWidthProfile}>
            <div className="2xl:h-[30px] bg-#FF6104-20 rounded-t-[5px]">
                <Typography variant="lead" className="uppercase font-semibold px-10">
                    {t('CreateItems')}
                </Typography>
            </div>
            {/* Inside AdminCompanies*/}
            <div className="px-10 flex flex-wrap gap-2 xl:gap-4">
                {companiesForCreateItems.map(adminComp => {
                    return <CompanyCard companyItem={adminComp} />
                })}
            </div>
        </div >
    )
}