import { Button, Card, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

interface DeleteCardProps {
    type: "BLOG" | "ITEM"
    onDoClickFunction: () => void
    onDoNotClickFunction: () => void
}

function DeleteCard(props: DeleteCardProps) {
    const { t } = useTranslation();

    return (
        <div className="w-full h-full">
            <Card >
                <CardBody className="p-0">
                    <Typography className="text-2xl 2xl:text-3xl text-#333333 font-bold uppercase text-center mt-[30px]">
                        {t("Delete")}
                    </Typography>
                    <div className="mx-auto w-[56px] 2xl:w-[70px] mt-[20px]">
                        {(
                            <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M28 0C12.5361 0 0 12.5361 0 28C0 43.4648 12.5361 56 28 56C43.4648 56 56 43.4648 56 28C56 12.5361 43.4648 0 28 0ZM28 52.5551C14.4909 52.5551 3.5 41.5091 3.5 27.9999C3.5 14.4908 14.4909 3.49989 28 3.49989C41.5091 3.49989 52.5 14.4908 52.5 27.9999C52.5 41.509 41.5091 52.5551 28 52.5551Z"
                                    fill="#EA4247"
                                />
                                <path d="M37 19L18 38M18 19L37 38" stroke="#EA4247" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        )}
                    </div>
                    <Typography className="text-#333333 text-base 2xl:text-xl font-light mx-auto w-fit mt-[20px] text-center">
                        {props.type === "BLOG" && t("AreYouSureYouWantToDeleteThisBlog")}
                        {props.type === "ITEM" && t("AreYouSureYouWantToDeleteThisItem")}
                    </Typography>
                </CardBody>
                <CardFooter className="flex flex-row justify-center items-center p-2 mx-auto mt-[30px]">
                    <Button id="cancelBtn" onClick={props.onDoNotClickFunction} className="bg-transparent hover:bg-transparent text-#FF8136 hover:text-#FF6104 h-[40px] w-[133px] text-base font-[500] p-0 uppercase shadow-none hover:shadow-none">
                        {t("Cancel")}
                    </Button>
                    <Button id="confirmBtn" onClick={props.onDoClickFunction} className="bg-#EA4247 hover:bg-#D12026 h-[40px] w-[133px] text-base font-[500] p-0 uppercase shadow-none hover:shadow-none">
                        {t("Delete")}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}

export default DeleteCard;
