import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateCompany, CreatePortalItem, EditCompany, EditPortalItem, PortalCompany, PortalItem, PortalSliceData, ProfileCompanies } from "./PortalModel";
import { handleFulfilled, handlePending, handleRejected, RejectedValue, StatusValue } from "../../app/common";
import { ErrorResponse } from "../Authentification/authModel";
import { apiAddress } from "../../app/apiModel";

// export const GetPortalCompany = createAsyncThunk<PortalCompany, string, { rejectValue: RejectedValue, shouldRefreshToken: boolean }>(
//     'PortalSlice/GetPortalCompany',
//     async (companyId: string, { rejectWithValue }) => {
//         const requestHeaders: HeadersInit = new Headers();
//         const accessToken = localStorage.getItem('webShopBimTAccessToken');
//         requestHeaders.set('Authorization', 'Bearer ' + accessToken);

//         const response = await fetch(apiAddress + 'company/getcompany?id=' + companyId, {
//             method: 'GET',
//             headers: requestHeaders,
//         })
//         if (response.status === 200) {
//             const dataResponse = (await response.json()) as PortalCompany;
//             return dataResponse;
//         }
//         else if (response.status === 401 || response.status === 402) {
//             return rejectWithValue({ message: response.statusText, status: response.status })
//         }
//         else {
//             const errorResponse = await response.json() as ErrorResponse;
//             return rejectWithValue({ message: errorResponse.detail, status: response.status });
//         }
//     }
// );

export const GetAllCompanies = createAsyncThunk<PortalCompany[], void, { rejectValue: RejectedValue, shouldRefreshToken: boolean }>(
    'PortalSlice/GetAllCompanies',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'company/getallcompanies', {
            method: 'GET',
            headers: requestHeaders,
        })
        if (response.status === 200) {
            const dataResponse = (await response.json()) as PortalCompany[];
            return dataResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const GetProfileCompanies = createAsyncThunk<ProfileCompanies, void, { rejectValue: RejectedValue, shouldRefreshToken: boolean }>(
    'PortalSlice/GetProfileCompanies',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'company/getprofilecompanies', {
            method: 'GET',
            headers: requestHeaders,
        })
        if (response.status === 200) {
            const dataResponse = (await response.json()) as ProfileCompanies;
            return dataResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const GetCompaniesByUser = createAsyncThunk<PortalCompany[], void, { rejectValue: RejectedValue, shouldRefreshToken: boolean }>(
    'PortalSlice/GetCompaniesByUser',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'company/getbyuser', {
            method: 'GET',
            headers: requestHeaders,
        })
        if (response.status === 200) {
            const dataResponse = (await response.json()) as PortalCompany[];
            return dataResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const PostCreatePortalCompany = createAsyncThunk<string, CreateCompany, { rejectValue: RejectedValue }>(
    'PortalSlice/PostCreatePortalCompany',
    async (company: CreateCompany, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'company/createcompany', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(company)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const PutEditPortalCompany = createAsyncThunk<string, EditCompany, { rejectValue: RejectedValue }>(
    'PortalSlice/PutEditPortalCompany',
    async (company: EditCompany, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'company/editcompany', {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(company)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const GetAllPortalItems = createAsyncThunk<PortalItem[], string, { rejectValue: RejectedValue, shouldRefreshToken: boolean }>(
    'PortalSlice/GetAllPortalItems',
    async (companyId: string, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'portalItem/getallportalitems?companyId=' + companyId, {
            method: 'GET',
            headers: requestHeaders,
        })
        if (response.status === 200) {
            const dataResponse = (await response.json()) as PortalItem[];
            return dataResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }

);

export const PostCreatePortalItem = createAsyncThunk<string, CreatePortalItem, { rejectValue: RejectedValue }>(
    'PortalSlice/CreatePortalItem',
    async (portalItem: CreatePortalItem, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'portalItem/createportalitem', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(portalItem)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const PutEditPortalItem = createAsyncThunk<string, EditPortalItem, { rejectValue: RejectedValue }>(
    'PortalSlice/EditPortalItem',
    async (portalItem: EditPortalItem, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'portalItem/editportalitem', {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(portalItem)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const PutDeletePortalItem = createAsyncThunk<string, string, { rejectValue: RejectedValue }>(
    'PortalSlice/DeletePortalItem',
    async (portalItemId: string, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'portalItem/deleteportalitem?id=' + portalItemId, {
            method: 'PUT',
            headers: requestHeaders
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

const initStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    error: "",
}

const initialPortalSliceData: PortalSliceData = {
    companies: [],
    profileCompanies: { ownerCompanies: [], adminCompanies: [], hasPortalLicense: false },
    portalItemsOfCompany: [],
    statusValues: initStatusValues
}

export const PortalSlice = createSlice({
    name: "PortalSlice",
    initialState: {
        portalSliceData: initialPortalSliceData
    },
    reducers: {
        resetStatusCode: (state) => {
            state.portalSliceData.statusValues.statusCode = '';
            state.portalSliceData.statusValues.error = '';
        },
        resetPortalSlice: (state) => {
            state.portalSliceData.companies = [];
            state.portalSliceData.portalItemsOfCompany = [];
            state.portalSliceData.statusValues = initStatusValues;
        },
    },
    extraReducers(builder) {
        builder
            //GetAllPortalItems
            .addCase(GetAllPortalItems.fulfilled, (state, action) => {
                state.portalSliceData.portalItemsOfCompany = handleFulfilled(state.portalSliceData.statusValues, action.payload.reverse());
            })
            .addCase(GetAllPortalItems.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetAllPortalItems.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //CreatePortalItem
            .addCase(PostCreatePortalItem.fulfilled, (state, action) => {
                state.portalSliceData.statusValues.statusCode = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(PostCreatePortalItem.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PostCreatePortalItem.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            // //GetCompany
            // .addCase(GetPortalCompany.fulfilled, (state, action) => {
            //     state.portalSliceData.companyToShow = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            // })
            // .addCase(GetPortalCompany.rejected, (state, action) => {
            //     handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            // })
            // .addCase(GetPortalCompany.pending, (state) => {
            //     handlePending(state.portalSliceData.statusValues);
            // })
            //GetAllCompanies
            .addCase(GetAllCompanies.fulfilled, (state, action) => {
                state.portalSliceData.companies = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(GetAllCompanies.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetAllCompanies.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //GetProfileCompanies
            .addCase(GetProfileCompanies.fulfilled, (state, action) => {
                state.portalSliceData.profileCompanies = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(GetProfileCompanies.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetProfileCompanies.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //GetCompaniesByUser
            .addCase(GetCompaniesByUser.fulfilled, (state, action) => {
                state.portalSliceData.companies = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(GetCompaniesByUser.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetCompaniesByUser.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //CreateCompany
            .addCase(PostCreatePortalCompany.fulfilled, (state, action) => {
                state.portalSliceData.statusValues.statusCode = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(PostCreatePortalCompany.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PostCreatePortalCompany.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //EditCompany
            .addCase(PutEditPortalCompany.fulfilled, (state, action) => {
                state.portalSliceData.statusValues.statusCode = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(PutEditPortalCompany.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PutEditPortalCompany.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //EditPortalItem
            .addCase(PutEditPortalItem.fulfilled, (state, action) => {
                state.portalSliceData.statusValues.statusCode = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(PutEditPortalItem.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PutEditPortalItem.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
            //DeletePortalItem
            .addCase(PutDeletePortalItem.fulfilled, (state, action) => {
                state.portalSliceData.statusValues.statusCode = handleFulfilled(state.portalSliceData.statusValues, action.payload);
            })
            .addCase(PutDeletePortalItem.rejected, (state, action) => {
                handleRejected(state.portalSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PutDeletePortalItem.pending, (state) => {
                handlePending(state.portalSliceData.statusValues);
            })
    },
});

export const { resetStatusCode, resetPortalSlice } = PortalSlice.actions;
export default PortalSlice.reducer;
