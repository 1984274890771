// CreateItemsPage

import { Button, Checkbox, Dialog, Typography } from "@material-tailwind/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { initialPortalItem, PortalCompany, PortalItem } from "../../features/Portal/PortalModel";
import { GetAllPortalItems, PostCreatePortalItem, PutDeletePortalItem, PutEditPortalItem, resetPortalSlice, resetStatusCode } from "../../features/Portal/PortalSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import SuccessCard from "../../features/CommonPages/successCard";
import { useParams } from "react-router-dom";
import PortalCard from "./PortalCard";
import Pagination from "../Pagination/Pagination";
import DeleteCard from "../../features/CommonPages/deleteCard";

function CreateItemsPage() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const portalData = useSelector((state: RootState) => state.PortalSlice);
    const { companyName } = useParams<{ companyName: string }>();
    const [portalItems, setPortalItems] = useState<PortalItem[]>([]);
    const [companiesForCreateItems, setCompaniesForCreateItems] = useState<PortalCompany[]>([])
    const [companyId, setCompanyId] = useState<string | undefined>();
    const [isCreateForm, setIsCreateForm] = useState(true);
    const [portalItem, setPortalItem] = useState<PortalItem>(initialPortalItem)
    const resetStates = () => { setPortalItem(initialPortalItem); setProductImageName(""); setTitleLength(0); setDescriptionLength(0) }

    const [productImageName, setProductImageName] = useState("");
    const [titleLength, setTitleLength] = useState(0);
    const [descriptionLength, setDescriptionLength] = useState(0);
    const productTitleLength = 150;
    const productDescriptionLength = 200;
    const productUnitOptions = [t("Meters"), 'm²' , t("Piece"), t("Area")];
    const productCurrencyOptions = ["EUR", "USD"];

    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
    const currencyDropdownRef = useRef<HTMLDivElement>(null);
    const currencyButtonRef = useRef<HTMLButtonElement>(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<string>()
    const handleDeleteDialog = () => { setOpenDeleteDialog(!openDeleteDialog); }
    const handleCloseDeleteDialog = () => { setOpenDeleteDialog(false); }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const handleOpenDialog = () => { setOpenDialog(!openDialog); }

    useEffect(() => {
        const combinedCompanies = [
            ...portalData.portalSliceData.profileCompanies.adminCompanies,
            ...portalData.portalSliceData.profileCompanies.ownerCompanies,
        ];

        setCompaniesForCreateItems(combinedCompanies);
        setCompanyId(combinedCompanies.find(company => company.name === companyName)?.id)
    }, [portalData.portalSliceData.profileCompanies])

    useEffect(() => {
        dispatch(resetPortalSlice())
        if (companyId) {
            dispatch(GetAllPortalItems(companyId));
        }
    }, [companyId]);

    useEffect(() => {
        setPortalItems(portalData.portalSliceData.portalItemsOfCompany);
    }, [portalData.portalSliceData.portalItemsOfCompany]);

    function handleOnClick(action: "CREATE" | "EDIT"): void {
        if (!companyId) {
            return;
        }
        let portalItemWithCompanyId = { ...portalItem, companyId }

        const isValid = action === "CREATE" ? !Object.entries(portalItemWithCompanyId).filter(([key]) => key !== "id").some(([_, value]) => value === "") : !Object.entries(portalItemWithCompanyId).some(([_, value]) => value === "")

        if (isValid && companiesForCreateItems.some(company => company.name === companyName)) {
            if (action === "CREATE") {
                dispatch(PostCreatePortalItem(portalItemWithCompanyId))
            }
            else {
                dispatch(PutEditPortalItem(portalItemWithCompanyId))
            }
        }
        else {
            setSuccess(false);
            setMessage(t("InsertAllTheData"))
            handleOpenDialog();
            return;
        }
    }

    const onProductTitleChange = (title: string) => {
        if (title.length >= productTitleLength + 1) return;
        setPortalItem((prevItem) => ({ ...prevItem, title }))
        setTitleLength(title.length);
    }

    const onProductDescriptionChange = (description: string) => {
        if (description.length >= productDescriptionLength + 1) return;
        setPortalItem((prevItem) => ({ ...prevItem, description }))
        setDescriptionLength(description.length);
    }

    function handleProductFileChange(e: ChangeEvent<HTMLInputElement>): void {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];

            setProductImageName(selectedFile.name);
            getBase64(selectedFile)
                .then(result => {
                    setPortalItem((prevItem) => ({ ...prevItem, photo: result }))
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    const getBase64 = (file: File): Promise<string> => {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result as string);
            };
        });
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setDropdownOpen(false);
                buttonRef.current?.blur();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                currencyDropdownRef.current &&
                !currencyDropdownRef.current.contains(event.target as Node)
            ) {
                setCurrencyDropdownOpen(false);
                currencyButtonRef.current?.blur();
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (portalData.portalSliceData.statusValues.statusCode === '200') {
            resetStates()
            setSuccess(true);
            handleOpenDialog();
            if (companyId) {
                dispatch(GetAllPortalItems(companyId));
            }
            if (itemToDelete) {
                setItemToDelete(undefined)
                setMessage(t("ItemDeleted"));
            }
            else {
                if (isCreateForm) {
                    setMessage(t("ItemCreated"));
                }
                else {
                    setMessage(t("ItemUpdated"));
                    setIsCreateForm(true)
                }
            }

        }
        else if (portalData.portalSliceData.statusValues.statusCode !== '') {
            setSuccess(false);
            setMessage(portalData.portalSliceData.statusValues.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
        dispatch(resetStatusCode());
    }, [portalData.portalSliceData.statusValues.statusCode])

    function handleDelete(id: string): void {
        setOpenDeleteDialog(true)
        setItemToDelete(id)
    }

    function handleEdit(id: string): void {
        setIsCreateForm(false)
        let item = portalData.portalSliceData.portalItemsOfCompany.find(item => item.id === id)
        if (item) {
            setPortalItem(item)

            const editFormDiv = document.getElementById('editForm');
            if (editFormDiv) {
                editFormDiv.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const renderProduct = (portalItem: PortalItem) => (
        <PortalCard key={portalItem.id} portalItem={portalItem} isAdmin={true} onDeleteClick={(id) => handleDelete(id)} onEditClick={(id) => handleEdit(id)} />
    );

    function handleCancel(): void {
        resetStates();
        setIsCreateForm(true);
    }

    const deletePortalItem = () => {
        if (itemToDelete) {
            dispatch(PutDeletePortalItem(itemToDelete));
        }
        handleCloseDeleteDialog()
    }

    return (companiesForCreateItems.some(comp => comp.name === companyName) ?
        <div className="font-roboto pb-[40px] bg-#FFFFFF">
            <div id="editForm" className="bg-#F6F6F6 h-[60px] xl:2xl:h-[70px] flex justify-center items-center">
                <h1 className="uppercase text-#333333 font-bold sm:text-[18px] lg:text-[20px] xl:text-[24px] 2xl:text-[36px]">
                    {t("CreateANewItem")}
                </h1>
            </div>
            <div className="container justify-center max-w-[90%] mx-auto mt-[60px] rounded-b-[5px] tracking-[0.7px] bg-#FFFFFF h-auto space-y-5 shadow-4s">
                <div className="py-[3%] mx-auto">
                    {/*Product*/}
                    <div className="flex flex-col lg:grid lg:grid-cols-2 px-[5%] gap-[5%]">
                        <div className="flex flex-col gap-4">
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("ProductTitle")}</Typography>
                                <textarea id="productTitle" placeholder={t("EnterProductTitle")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pt-[10px] pb-[5px] pl-[15px] pr-[15px] resize-none w-full h-[64px]"
                                    value={portalItem.title} onChange={(event) => onProductTitleChange(event.target.value)} required></textarea>
                                <div className="flex flex-row justify-start">
                                    <Typography className="text-#A0A0A0 text-[12px] font-light lowercase">{titleLength + "/" + productTitleLength + " " + t("Caracters")}</Typography>
                                </div>
                            </div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("ProductCode")}</Typography>
                                <input id="productCode" placeholder={t("EnterProductCode")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px]"
                                    value={portalItem.code} onChange={(e) => setPortalItem((prevItem) => ({ ...prevItem, code: e.target.value }))} required></input>
                            </div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("ProductType")}</Typography>
                                <input id="productType" placeholder={t("EnterProductType")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px]"
                                    value={portalItem.type} onChange={(e) => setPortalItem((prevItem) => ({ ...prevItem, type: e.target.value }))} required></input>
                            </div>
                            <div className="flex gap-3">
                                <div className="w-[100%]">
                                    <Typography className="text-#333333 text-[16px] font-light">{t("ProductPrice")}</Typography>
                                    <input id="productPrice" type="number" placeholder={t("EnterProductPrice")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px]"
                                        value={portalItem.price} onChange={(e) => setPortalItem((prevItem) => ({ ...prevItem, price: parseInt(e.target.value) }))} required></input>
                                </div>
                                <div className="w-[320px]">
                                    <div>
                                        <Typography className="text-#333333 text-[16px] font-light">{t("SelectProductCurrency")}</Typography>
                                        <div ref={currencyDropdownRef} className="relative w-full">
                                            <button ref={currencyButtonRef}
                                                onClick={() => { setCurrencyDropdownOpen(!currencyDropdownOpen); if (currencyDropdownOpen) currencyButtonRef.current?.blur(); }}
                                                className={`text-[14px] ${portalItem.currency !== "" ? "text-#333333" : "text-#A0A0A0"} bg-#F6F6F6 rounded-[5px] border border-#788090-20 px-[15px] w-full h-[40px] flex justify-between items-center focus:ring-1 focus:ring-#FF8136 focus:border-#FF8136 cursor-pointer`}>
                                                {portalItem.currency || t("Select") + "..."}
                                                <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 transform transition-transform duration-200 ${currencyDropdownOpen ? "rotate-180" : ""}`} viewBox="0 0 20 20" fill="currentColor" >
                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                            <ul className={`absolute z-10 mt-1 p-[10px] w-full bg-white border border-#788090-20 rounded-[5px] shadow-lg transition-all duration-200 origin-top ${currencyDropdownOpen ? "scale-100 opacity-100" : "scale-95 opacity-0 pointer-events-none"}`}>
                                                {productCurrencyOptions.map((option, index) => (
                                                    <li key={index} onClick={() => { setPortalItem((prevItem) => ({ ...prevItem, currency: option })); setCurrencyDropdownOpen(false); }} className={`text-[14px] px-4 py-2 cursor-pointer rounded-[5px] hover:text-#333333 hover:bg-#F6F6F6 ${option === portalItem.currency ? "text-#333333 bg-#F6F6F6" : "text-#A0A0A0"}`}> {option}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("ProductDescription")}</Typography>
                                <textarea id="description" placeholder={t("Description")} className="text-[14px] placeholder:text-#A0A0A0 text-#333333 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pt-[10px] pb-[5px] pl-[15px] pr-[15px] resize-none w-full h-[64px]"
                                    value={portalItem.description} onChange={(event) => onProductDescriptionChange(event.target.value)} required></textarea>
                                <div className="flex flex-row justify-start">
                                    <Typography className="text-#A0A0A0 text-[12px] font-light lowercase">{descriptionLength + "/" + productDescriptionLength + " " + t("Caracters")}</Typography>
                                </div>
                            </div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("UploadProductImage")}</Typography>
                                <div className="flex items-center">
                                    <label htmlFor="productFileInput" className={` ${productImageName !== "" ? "" : "text-#A0A0A0"} flex flex-row items-center text-[14px] overflow-hidden whitespace-nowrap text-ellipsis bg-#F6F6F6 rounded-l-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px] flex-grow cursor-pointer`} >
                                        {productImageName !== "" ? productImageName : t("ChooseFilesToUpload")}
                                    </label>
                                    <label htmlFor="productFileInput" className={`${i18n.language === 'en' ? 'w-[25%]' : 'w-[35%]'} flex justify-center text-[14px] font-bold bg-#FFFFFF border-[1px] border-#788090-20 text-#333333 rounded-r-[5px] h-[40px] cursor-pointer`}>
                                        <input id="productFileInput" type="file" accept='image/*' className="hidden" onChange={(event) => handleProductFileChange(event)} required />
                                        <button className="pointer-events-none">{t("BrowseFiles")}</button>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("SelectProductUnit")}</Typography>
                                <div ref={dropdownRef} className="relative w-full">
                                    <button ref={buttonRef}
                                        onClick={() => { setDropdownOpen(!dropdownOpen); if (dropdownOpen) buttonRef.current?.blur(); }}
                                        className={`text-[14px] ${portalItem.unit !== "" ? "text-#333333" : "text-#A0A0A0"} bg-#F6F6F6 rounded-[5px] border border-#788090-20 px-[15px] w-full h-[40px] flex justify-between items-center focus:ring-1 focus:ring-#FF8136 focus:border-#FF8136 cursor-pointer`}>
                                        {portalItem.unit || t("Select") + "..."}
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 transform transition-transform duration-200 ${dropdownOpen ? "rotate-180" : ""}`} viewBox="0 0 20 20" fill="currentColor" >
                                            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    <ul className={`absolute z-10 mt-1 p-[10px] w-full bg-white border border-#788090-20 rounded-[5px] shadow-lg transition-all duration-200 origin-top ${dropdownOpen ? "scale-100 opacity-100" : "scale-95 opacity-0 pointer-events-none"}`}>
                                        {productUnitOptions.map((option, index) => (
                                            <li key={index} onClick={() => { setPortalItem((prevItem) => ({ ...prevItem, unit: option })); setDropdownOpen(false); }} className={`text-[14px] px-4 py-2 cursor-pointer rounded-[5px] hover:text-#333333 hover:bg-#F6F6F6 ${option === portalItem.unit ? "text-#333333 bg-#F6F6F6" : "text-#A0A0A0"}`}> {option}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="mb-[2px] mt-auto" >
                                <Checkbox label={"Public"} color="orange" checked={portalItem.isPublic} onChange={(e) => setPortalItem((prevItem) => ({ ...prevItem, isPublic: e.target.checked }))} />
                            </div>
                            <div className="flex flex-col items-end pt-[3%]">
                                {isCreateForm ?
                                    <Button id="publishItemBtn" onClick={() => handleOnClick("CREATE")} className={`bg-#FF8136 hover:bg-#FF6104 h-[40px] text-base font-[700] py-0 px-5 uppercase shadow-none hover:shadow-none`}>
                                        {t("PublishItem")}
                                    </Button> :
                                    <div className="space-x-3">
                                        <Button id="cancel" onClick={handleCancel} className={`bg-#FF8136 hover:bg-#FF6104 h-[40px] text-base font-[700] py-0 px-5 uppercase shadow-none hover:shadow-none`}>
                                            {t("Cancel")}
                                        </Button>
                                        <Button id="saveEditingBtn" onClick={() => handleOnClick("EDIT")} className={`bg-#FF8136 hover:bg-#FF6104 h-[40px] text-base font-[700] py-0 px-5 uppercase shadow-none hover:shadow-none`}>
                                            {t("SaveChanges")}
                                        </Button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {portalItems?.length > 0 ? <Pagination items={portalItems} renderItem={renderProduct} itemsLength={portalItems.length} /> : <></>}
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={message} success={success} />
            </Dialog>

            <Dialog open={openDeleteDialog} handler={handleDeleteDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <DeleteCard onDoClickFunction={deletePortalItem} onDoNotClickFunction={handleCloseDeleteDialog} type={"ITEM"} />
            </Dialog>
        </div> : <div>Unauthorized</div>
    );
}

export default CreateItemsPage;