import { Button, IconButton } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import LeftArrow from "../../Images/leftArrow.png";
import RightArrow from "../../Images/rightArrow.png";
import LeftEndArrow from "../../Images/leftEndArrow.png";
import RightEndArrow from "../../Images/rightEndArrow.png.png";

type PaginationProps = {
    items: any[];
    itemsLength: number;
    renderItem: (item: any) => JSX.Element;
};

function Pagination(props: PaginationProps) {
    let sixItems: number = 6;
    let nineItems: number = 9;
    let twelveItems: number = 12;
    let tabletScreenSize: number = 640;
    let fHDScreenSize: number = 1024;
    let twoKScreenSize: number = 1380;
    let fourKScreenSize: number = 2080;

    const startIndexRef = useRef<number>(1);
    const endIndexRef = useRef<number>(1);
    const pagesCountRef = useRef<number>(1);

    const [active, setActive] = useState(1);
    const [items, setItems] = useState<any[]>(props.items);
    const [itemsLength, setItemsLength] = useState<number>(props.itemsLength);
    const [itemsToShow, setItemsToShow] = useState<any[]>([]);
    // const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [itemsPerPage, setItemsPerPage] = useState<number>(0);

    const getItemProps = (index: number) =>
    ({
        className:
            active === index
                ? "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#FFFFFF bg-#4E4E4E shadow-none hover:shadow-none"
                : "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#4E4E4E bg-#FFFFFF shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent",
        variant: active === index ? "filled" : "text",
        onClick: () => select(index),
    } as any);

    const next = (step: number) => {
        const newActive = Math.min(active + step, pagesCountRef.current);
        const newStartIndex = (newActive - 1) * itemsPerPage;
        const newEndIndex = Math.min(newStartIndex + itemsPerPage, itemsLength);

        setActive(newActive);

        startIndexRef.current = newStartIndex;
        endIndexRef.current = newEndIndex;

        setItemsToShow(items.slice(newStartIndex, newEndIndex));
    };

    const prev = (step: number) => {
        const newActive = Math.max(active - step, 1);
        const newStartIndex = (newActive - 1) * itemsPerPage;
        const newEndIndex = newStartIndex + itemsPerPage;

        setActive(newActive);

        startIndexRef.current = newStartIndex;
        endIndexRef.current = newEndIndex;

        setItemsToShow(items.slice(newStartIndex, newEndIndex));
    };

    const select = (step: number) => {
        setActive(step);

        startIndexRef.current = (step - 1) * itemsPerPage;
        endIndexRef.current = startIndexRef.current + itemsPerPage;

        setItemsToShow(items.slice(startIndexRef.current, endIndexRef.current));
    };

    function CreateItems() {
        return (
            <div className="container flex flex-wrap justify-center gap-[5%] xl:gap-[1%] 2xl:gap-[5%] max-w-[90%] mx-auto mt-[60px]">
                {itemsToShow?.map((item, index) => {
                    const isHidden =
                        (window.innerWidth < tabletScreenSize && index >= sixItems) ||
                        (window.innerWidth >= tabletScreenSize && window.innerWidth < fHDScreenSize && index >= sixItems) ||
                        (window.innerWidth >= fHDScreenSize && window.innerWidth < twoKScreenSize && index >= sixItems) ||
                        (window.innerWidth >= twoKScreenSize && window.innerWidth < fourKScreenSize && index >= nineItems) ||
                        (window.innerWidth >= fourKScreenSize && index >= twelveItems);
                    return (
                        <div key={index} className={`flex ${isHidden ? "hidden" : ""}`}>
                            {props.renderItem(item)}
                        </div>
                    );
                })}
            </div>
        );
    }

    function CreatePages() {
        const pagesToCreate = [];

        for (let i = 1; i <= pagesCountRef.current; i++) {
            pagesToCreate.push(
                <IconButton key={i} {...getItemProps(i)}>
                    {i}
                </IconButton>
            );
        }

        return (
            <div className="flex justify-center items-center gap-2">
                {pagesToCreate}
            </div>
        );
    }

    function PagesReload() {
        if (window.innerWidth < tabletScreenSize) {
            pagesCountRef.current = itemsLength <= sixItems ? 1 : Math.ceil(itemsLength / sixItems);
            setItemsPerPage(sixItems);

        } else if (window.innerWidth >= tabletScreenSize && window.innerWidth < fHDScreenSize) {
            pagesCountRef.current = itemsLength <= sixItems ? 1 : Math.ceil(itemsLength / sixItems);
            setItemsPerPage(sixItems);

        } else if (window.innerWidth >= fHDScreenSize && window.innerWidth < twoKScreenSize) {
            pagesCountRef.current = itemsLength <= sixItems ? 1 : Math.ceil(itemsLength / sixItems);
            setItemsPerPage(sixItems);

        } else if (window.innerWidth >= twoKScreenSize && window.innerWidth < fourKScreenSize) {
            pagesCountRef.current = itemsLength <= nineItems ? 1 : Math.ceil(itemsLength / nineItems);
            setItemsPerPage(nineItems);

        } else if (window.innerWidth >= fourKScreenSize) {
            pagesCountRef.current = itemsLength <= twelveItems ? 1 : Math.ceil(itemsLength / twelveItems);
            setItemsPerPage(twelveItems);
        }
    }

    useEffect(() => {
        setItems(props.items);
        setItemsLength(props.itemsLength)
        setItemsToShow(props.items);
        PagesReload();

        const handleResize = () => {
            PagesReload();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [props.items, props.itemsLength]);

    return (itemsLength > 0 ?
        <div>
            <CreateItems />
            <div className="flex justify-center items-center mt-[40px] xl:2xl:mt-[60px]">
                <div className="flex justify-center items-center gap-5 mr-3">
                    <Button id="leftEndArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => prev(pagesCountRef.current)} disabled={active === 1}>
                        <img src={LeftEndArrow} className="h-[14px] w-[16px]" alt=""></img>
                    </Button>
                    <Button id="leftArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => prev(1)} disabled={active === 1}>
                        <img src={LeftArrow} className="h-[14px] w-[8px]" alt=""></img>
                    </Button>
                </div>
                <CreatePages />
                <div className="flex justify-center items-center gap-5 ml-3">
                    <Button id="rightArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => next(1)} disabled={active === pagesCountRef.current}>
                        <img src={RightArrow} className="h-[14px] w-[8px]" alt=""></img>
                    </Button>
                    <Button id="rightEndArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => next(pagesCountRef.current)} disabled={active === pagesCountRef.current}>
                        <img src={RightEndArrow} className="h-[14px] w-[16px]" alt=""></img>
                    </Button>
                </div>
            </div>
        </div> : <></>
    );
}

export default Pagination;