import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { GetUser, resetAccount, resetStatusCode } from "../Accounts/accountSlice";
import { resetAuthentificationData } from "../Authentification/authentificationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { resetStripeSlice } from "../stripe/stripeSlice";
import { resetLicensesSlice } from "../Licenses/licensesSlice";
import { resetDirectorySlice } from "../Directories/directorySlice";
import Logo from "../../Images/bimagine.png";
import TopBtnImage from "../../Images/TopBtnImage.png"
import { Navbar, Typography, Button, IconButton, Menu, MenuHandler, MenuItem, MenuList, Collapse } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../app/common";

export function Header() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const accountData = useSelector((state: RootState) => state.AccountSlice);
  const [logedUser, setLogedUser] = useState("Login");
  const [openNav, setOpenNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const changeLanguage = (lng: string) => { i18n.changeLanguage(lng); };
  const scrollValue = 500;
  const location = useLocation();
  const isBlogAdmin = localStorage.getItem('role') === UserRole.BlogAdmin;
  const [activePage, setActivePage] = useState("");

  const navigate = useNavigate();

  const handlePageClick = (page: Page) => {
    setActivePage((prev) => (prev === page.displayText ? "" : page.displayText));
    if (page.onClick !== undefined) {
      page.onClick();
    }
  };

  const loginClicked = () => {
    if (logedUser === "User") {
      navigate("/createUserData");
    } else if (accountData.accountSliceData.accountData.loggedUserData.firstName === "") {
      dispatch(resetStatusCode());
      navigate("/login");
    }
    setOpenNav(false);
  };

  const userClicked = () => {
    if (accountData.accountSliceData.accountData.loggedUserData.firstName !== "") {
      dispatch(resetStatusCode());
      navigate("/profilePage");
    }
    else if (accountData.shouldCreateData) {
      loginClicked();
    }
  };

  const logoutClicked = () => {
    dispatch(resetAccount());
    dispatch(resetAuthentificationData());
    dispatch(resetStripeSlice());
    dispatch(resetLicensesSlice());
    dispatch(resetDirectorySlice());
    navigate("/");
    setOpenNav(false);
  };

  const createBlogClicked = () => {
    navigate("/allBlogPostsPage");
    setOpenNav(false);
  }

  const createItemsClicked = () => {
    navigate("/portal/createItems");
    setOpenNav(false);
  }

  window.onscroll = () => {
    scrollFunction();
  };

  function scrollFunction() {
    if (document.body.scrollTop > scrollValue || document.documentElement.scrollTop > scrollValue) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  function scrollToTop() {
    const scrollElement = document.documentElement || document.body;
    scrollElement.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    if (accountData.shouldCreateData && !accountData.hasUserData) {
      setLogedUser("User");
    } else if (!accountData.shouldCreateData && !accountData.hasUserData) {
      setLogedUser("Login");
    }
  }, [accountData.shouldCreateData]);

  useEffect(() => {
    if (accountData.hasUserData && accountData.accountSliceData.accountData.loggedUserData.firstName === "") {
      dispatch(GetUser());
    }
  }, [accountData.hasUserData]);

  useEffect(() => {
    if (accountData.accountSliceData.accountData.loggedUserData.firstName !== "") {
      setLogedUser(accountData.accountSliceData.accountData.loggedUserData.firstName + " " + accountData.accountSliceData.accountData.loggedUserData.lastName);
    } else if (accountData.shouldCreateData && !accountData.hasUserData) {
      setLogedUser("User");
    } else {
      setLogedUser("Login");
    }
  }, [accountData.accountSliceData.accountData.loggedUserData]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 640 && setOpenNav(false)
    );
  }, []);

  interface Page {
    displayText: string;
    href?: string;
    href1?: string;
    onClick?: () => void;
    subPages?: Page[];
  }

  const pages: Page[] = [
    {
      displayText: t("About"),
      href: "/aboutPage",
    },
    {
      displayText: t("Products"),
      href: "/productsPage/tgaconcept",
      href1: "/productsPage/calculationtools",
    },
    {
      displayText: t("Portal"),
      href: "/portal",
      // subPages: [
      //   {
      //     displayText: t("AllBrochures"),
      //     href: "/portal/allBrochures",
      //   },
      //   {
      //     displayText: t("CreateBrochure"),
      //     href: "/portal/createBrochure",
      //   },
      // ]
    },
    {
      displayText: t("Blog"),
      href: "/blogPage",
    },
    {
      displayText: t("FAQ"),
      href: "/faqPage",
    },
    {
      displayText: t("Contact"),
      href: "/#contact",
    },
    {
      displayText: t("Forum"),
      href: "https://forum.bimagine.com/login",
    },
  ];

  const loggedUserPages: Page[] = [
    {
      displayText: t("About"),
      href: "/aboutPage",
    },
    {
      displayText: t("Products"),
      href: "/productsPage/tgaconcept",
      href1: "/productsPage/calculationtools",
    },
    {
      displayText: t("Portal"),
      href: "/portal",
    },
    {
      displayText: t("Blog"),
      href: "/blogPage",
    },
    {
      displayText: t("FAQ"),
      href: "/faqPage",
    },
    {
      displayText: t("Contact"),
      href: "/#contact",
    },
    {
      displayText: t("Forum"),
      href: "https://forum.bimagine.com/login",
    },
    {
      displayText: t("MyProfile"),
      href: `${accountData.shouldCreateData ? "/createUserData" : "/profilePage"}`,
    },
    {
      displayText: isBlogAdmin ? t("CreateBlog") : "",
      href: isBlogAdmin ? "/allBlogPostsPage" : "",
      onClick: isBlogAdmin ? createBlogClicked : undefined,
    },
    {
      displayText: t("Logout"),
      href: "#",
      onClick: logoutClicked,
    },
  ];

  const navList = (
    <ul className="flex flex-col gap-2 lg:mb-0 lg:mt-0 xl:flex-row lg:items-center lg:gap-6">
      {pages.map((page) => (
        <li key={page.displayText} className="relative group">
          {/*Main Page*/}
          <Typography className={`${location.pathname === page.href || location.pathname === page.href1 ? "text-#FF6104" : "text-#FFFFFF"} p-1 font-light uppercase hover:text-#FF6104 duration-300 sm:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px]`}>
            <a href={page.href} className="flex items-center cursor-pointer">{page.displayText}</a>
          </Typography>
          {/*Sub Pages*/}
          {page.subPages && (
            <ul className="absolute left-1/2 transform -translate-x-1/2 hidden group-hover:flex flex-col bg-#28373D py-2 rounded-[5px]">
              {page.subPages.map((subPage) => (
                <li key={subPage.displayText} className="px-4 py-2">
                  <a key={page.displayText + "-" + subPage.displayText} href={subPage.href} className="block text-#FFFFFF hover:text-#FF6104 text-center duration-300 uppercase whitespace-nowrap">{subPage.displayText}</a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );

  const menuNavList = (
    <ul className="flex flex-col gap-2 items-center">
      {pages.map((page) => (
        <li key={page.displayText} className="relative group">
          {/*Main Page*/}
          <a onMouseLeave={() => handlePageClick(page)} onMouseEnter={() => handlePageClick(page)} key={page.displayText} href={page.href} className={`group relative flex items-center justify-center p-1 font-light uppercase ${location.pathname === page.href || location.pathname === page.href1 ? "text-#FF6104" : "text-#FFFFFF"} hover:text-#FF6104 duration-300 text-lg cursor-pointer`} >
            {page.displayText}
            {page.subPages && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" className={`absolute right-[100px] w-[14px] h-[14px] ${activePage === page.displayText ? "rotate-180" : ""} transition-transform duration-300`} >
                <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393   c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393   s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" className="fill-current" />
              </svg>
            )}
          </a>
          {/*Sub Pages*/}
          {page.subPages && activePage === page.displayText && (
            <ul className="pt-2">
              {page.subPages && page.subPages.map((subPage) => (
                <li key={page.displayText + "-" + subPage.displayText}>
                  <a key={page.displayText + "-" + subPage.displayText} href={subPage.href} className="block text-#FFFFFF hover:text-#FF6104 text-center uppercase duration-300 text-lg p-1">{subPage.displayText}</a>
                </li>
              ))}
            </ul>
          )}
          < span className="block h-[1px] w-[320px] mt-2 bg-gradient-to-r from-transparent via-#FF6104 to-transparent" ></span>
        </li >
      ))
      }
      <Button id="loginHeaderBtn" variant="filled" size="sm" className="mb-2 bg-#FF6104 shadow-none hover:bg-#FF8136 hover:shadow-none duration-300 rounded-[5px] font-[100px]" onClick={loginClicked} >
        <span className="text-lg">{t("Login")}</span>
      </Button>
    </ul >
  );

  const loggedUserMenuNavList = (
    <ul className="flex flex-col gap-2 items-center">
      {loggedUserPages.map((page, index) => (
        page.displayText &&
        <li key={index} className="relative group">
          {/*Main Page*/}
          <a key={index} onMouseLeave={() => handlePageClick(page)} onMouseEnter={() => handlePageClick(page)} id={index.toString()} href={page.href} className={`group relative flex items-center justify-center p-1 font-light uppercase ${location.pathname === page.href || location.pathname === page.href1 ? "text-#FF6104" : "text-#FFFFFF"} hover:text-#FF6104 duration-300 text-lg cursor-pointer`} >
            {page.displayText}
            {page.subPages && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" className={`absolute right-[100px] w-[14px] h-[14px] ${activePage === page.displayText ? "rotate-180" : ""} transition-transform duration-300`} >
                <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393   c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393   s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" className="fill-current" />
              </svg>
            )}
          </a>
          {/*Sub Pages*/}
          {
            page.subPages && activePage === page.displayText && (
              <ul className="pt-2">
                {page.subPages && page.subPages.map((subPage) => (
                  <li key={page.displayText + "-" + subPage.displayText}>
                    <a key={page.displayText + "-" + subPage.displayText} href={subPage.href} className="block text-#FFFFFF hover:text-#FF6104 text-center uppercase duration-300 text-lg p-1">{subPage.displayText}</a>
                  </li>
                ))}
              </ul>
            )
          }
          {index !== loggedUserPages.length - 1 && (<span className="block h-[1px] w-[320px] mt-2 bg-gradient-to-r from-transparent via-#FF6104 to-transparent"></span>)}
        </li>
      ))
      }
    </ul >
  );

  function ProfileMenu() {
    const closeMenu = () => setIsMenuOpen(false);

    return (
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end" allowHover >
        <MenuHandler className="hidden xl:flex shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0">
          <div className="shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent" onClick={closeMenu} >
            {accountData.accountSliceData.accountData.loggedUserData.firstName !== "" || accountData.shouldCreateData ? (
              <Button id="profileBtn" size="sm" ripple={false} className="bg-transparent shadow-none hover:shadow-none cursor-default p-0">
                <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#FFFFFF shadow-none hover:stroke-#FF6104 hover:shadow-none duration-300 cursor-pointer md:block hidden xl:w-5 xl:h-5 2xl:w-7 2xl:h-7" >
                  <circle cx="7.26352" cy="4.57895" r="3.57895" />
                  <ellipse cx="7.26316" cy="14.4227" rx="6.26316" ry="3.57895" />
                </svg>
              </Button>
            ) : (
              <Button id="loginHeaderBtn" variant="filled" size="sm" className="hidden xl:flex items-center justify-center bg-#FF6104 shadow-none hover:bg-#FF8136 hover:shadow-none duration-300 rounded-[5px] sm:h-7 md:h-8 xl:h-10 2xl:h-11" onClick={loginClicked} >
                <span className="sm:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px]">{t("Login")}</span>
              </Button>
            )}
          </div>
        </MenuHandler>
        <MenuList hidden={accountData.accountSliceData.accountData.loggedUserData.firstName === "" && accountData.shouldCreateData === false} className="bg-#28373D border-transparent space-y-3" >
          <MenuItem
            id="myProfileDropdownBtn"
            hidden={accountData.accountSliceData.accountData.loggedUserData.firstName === "" && accountData.shouldCreateData === false}
            onClick={userClicked}
            className={`${location.pathname === "/profilePage" ? "text-#FF6104" : "text-#FFFFFF"} p-1 font-light text-center uppercase hover:text-#FF6104 focus:text-#FF6104 active:text-#FF6104 duration-300 
            sm:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent`}>
            {t("MyProfile")}
          </MenuItem>
          {
            localStorage.getItem('role') === UserRole.BlogAdmin ?
              <MenuItem
                id="createBlogDropdownBtn"
                hidden={accountData.accountSliceData.accountData.loggedUserData.firstName === "" && accountData.shouldCreateData === false}
                onClick={createBlogClicked}
                className={`${location.pathname === "/allBlogPostsPage" ? "text-#FF6104" : "text-#FFFFFF"} p-1 font-light text-center uppercase hover:text-#FF6104 focus:text-#FF6104 active:text-#FF6104 duration-300 
                sm:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent`}>
                {t("CreateBlog")}
              </MenuItem> :
              <></>
          }
          <MenuItem
            id="logoutDropdownBtn"
            hidden={accountData.accountSliceData.accountData.loggedUserData.firstName === "" && accountData.shouldCreateData === false}
            onClick={logoutClicked}
            className="p-1 font-light text-center uppercase text-#FFFFFF hover:text-#FF6104 focus:text-#FF6104 active:text-#FF6104 duration-300 
            sm:text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent">
            {t("Logout")}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <div className="sticky top-0 z-20 max-h-[768px] w-[100%] bg-#28373D font-roboto">
      <Navbar className="h-max max-w-full rounded-none bg-#28373D border-none shadow-none">
        <div className="flex items-center justify-between h-[20px] xl:h-[30px] 2xl:h-[40px] px-[10px] sm:px-[20px] md:px-[40px] lg:px-[55px] xl:px-[70px] 2xl:px-[90px]">
          <Typography as="a" href="/" className="cursor-pointer">
            <img src={Logo} alt="Logo" title="Logo" className="w-[70px] h-[15px] md:w-[80px] md:h-[16px] lg:w-[85px] lg:h-[18px] xl:w-[103px] xl:h-[20px] 2xl:w-[150px] 2xl:h-[30px]" />
          </Typography>
          <div className="flex items-center justify-center gap-[5px] lg:gap-[10px] xl:gap-[15px] 2xl:gap-[20px]">
            <div className="hidden xl:block">{navList}</div>
            <div className="inline-flex">
              <Button id="languageEnBtn" onClick={() => changeLanguage("en")} size="sm" variant="text" ripple={false} className="shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent pr-1" >
                <Typography className={`${i18n.language === 'en' ? 'text-#FF6104' : 'text-#FFFFFF'} text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-thin active:text-#FF6104 hover:text-#FF6104 duration-300`}>EN</Typography>
              </Button>
              <div className="w-[0.5px] lg:xl:2xl:w-[1.2px] h-[12px] lg:h-[16px] xl:h-[18px] 2xl:h-[22px] m-auto bg-#FFFFFF"></div>
              <Button id="languageDeBtn" onClick={() => changeLanguage("de")} size="sm" variant="text" ripple={false} className="shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent pl-1" >
                <Typography className={`${i18n.language === 'de' ? 'text-#FF6104' : 'text-#FFFFFF'} text-[10px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-thin active:text-#FF6104 hover:text-#FF6104 duration-300`}>DE</Typography>
              </Button>
            </div>
            <ProfileMenu />
            <IconButton variant="text" className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent xl:hidden" ripple={false} onClick={() => setOpenNav(!openNav)} >
              {openNav ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="h-6 w-6" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" stroke="currentColor" strokeWidth={2} >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav} className="flex-col items-center">
          {accountData.accountSliceData.accountData.loggedUserData.firstName === "" && accountData.shouldCreateData === false ? menuNavList : loggedUserMenuNavList}
        </Collapse>
      </Navbar>
      <div id="GoToTop" className={`${isVisible ? 'block' : 'hidden'} cursor-pointer opacity-100 hover:opacity-80 fixed bottom-4 right-4 w-[60px] h-[60px] hover:bg-transparent focus:bg-transparent active:bg-transparent`} onClick={() => scrollToTop()} >
        <img src={TopBtnImage} alt="GoToTop" />
      </div>
    </div>
  );
}
