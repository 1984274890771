import { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AppDispatch, RootState } from '../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { CreateBlogRequest, EditBlogRequest } from '../../features/Blogs/BlogModel';
import { ReturnFullBlogHTML, UserRole } from '../../app/common';
import { GetBlogById, PostCreateBlog, PutBlog } from '../../features/Blogs/BlogSlice';
import { Button, Chip, Dialog, Typography } from '@material-tailwind/react';
import SuccessCard from '../../features/CommonPages/successCard';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

function CreateBlogPage() {
    const blogData = useSelector((state: RootState) => state.BlogsSlice);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const blogTitleLength = 150;
    const blogDescriptionLength = 200;
    const blogTextLength = 8000;
    const [creator, setCreator] = useState("");
    const [tag, setTag] = useState("");
    const [tagsArray, setTagsArray] = useState<string[]>([]);
    const [readingTime, setReadingTime] = useState("")
    const [title, setTitle] = useState("")
    const [titleLength, setTitleLength] = useState(0);
    const [description, setDescription] = useState("")
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [text, setText] = useState("");
    const [textLength, setTextLength] = useState(0);
    const [blogImage, setBlogImage] = useState<string>("")
    const [blogName, setBlogName] = useState<string>("")
    const [openTag, setOpenTag] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const handleOpenDialog = () => { setOpenDialog(!openDialog); }

    var modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],

            [{ 'header': [1, 2, 3, 4, false] }],

            [{ 'color': [] }],
            [{ 'align': [] }],

            ['clean']
        ]
    }

    function handlePublishBlogClick(): void {
        if (blogImage !== "" && tagsArray.length !== 0 && readingTime !== "" && title !== "" && text !== "" && description !== "" && creator !== "") {
            var blogData: CreateBlogRequest = {
                image: blogImage,
                tags: tagsArray,
                minutesToRead: readingTime,
                title: title,
                text: ReturnFullBlogHTML(text),
                description: description,
                creator: creator
            }

            const params = new URLSearchParams(window.location.search);
            let blogId = params.get("blogId") ?? "none";

            if (blogId === "none") {
                dispatch(PostCreateBlog(blogData))
            }
            else {
                var editData: EditBlogRequest = {
                    blogId: blogId,
                    image: blogImage,
                    tags: tagsArray,
                    minutesToRead: readingTime,
                    title: title,
                    text: ReturnFullBlogHTML(text),
                    description: description,
                    creator: creator
                }
                dispatch(PutBlog(editData));
            }
            navigate({
                pathname: "/allBlogPostsPage",
                search: createSearchParams({ message: message }).toString()
            });
        }
        else {
            setSuccess(false);
            setMessage(t("InsertAllTheData"))
            handleOpenDialog();
        }
    }

    function handleFileChange(e: ChangeEvent<HTMLInputElement>): void {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];

            setBlogName(selectedFile.name);
            getBase64(selectedFile)
                .then(result => {
                    setBlogImage(result);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    const getBase64 = (file: File): Promise<string> => {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result as string);
            };
        });
    };

    const setBlogTitle = (title: string) => {
        if (title.length >= blogTitleLength + 1) return;
        setTitle(title);
        setTitleLength(title.length);
    }

    const setBlogDescription = (description: string) => {
        if (description.length >= blogDescriptionLength + 1) return;
        setDescription(description);
        setDescriptionLength(description.length);
    }

    const setBlogText = (text: string) => {
        if (description.length >= blogTextLength + 1) return;
        setText(text);
        setTextLength(text.length);
    }

    const checkTagName = (tagName: string) => {
        if (tagName.includes(";")) { return; }
        else { setTag(tagName); }
    }

    const handleTagClose = (tagIndex: number) => {
        const newTagsArray = [...tagsArray];
        newTagsArray.splice(tagIndex, 1);
        setTagsArray(newTagsArray);
    };

    const handleKeyDownOnTags = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && tag.trim() !== "") {
            setTagsArray([...tagsArray, tag.trim()]);
            setTag("");
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let blogId = params.get("blogId") ?? "none";
        dispatch(GetBlogById(blogId))
    }, [location]);

    useEffect(() => {
        if (blogData.blogSliceData.blogData.blog) {
            setCreator(blogData.blogSliceData.blogData.blog.creator);
            setTagsArray(blogData.blogSliceData.blogData.blog.tags);
            setBlogImage(blogData.blogSliceData.blogData.blog.image);
            setReadingTime(blogData.blogSliceData.blogData.blog.minutesToRead);
            setBlogTitle(blogData.blogSliceData.blogData.blog.title);
            setBlogDescription(blogData.blogSliceData.blogData.blog.description);
            setBlogText(blogData.blogSliceData.blogData.blog.text);
        }
    }, [blogData.blogSliceData.blogData]);

    useEffect(() => {
        setCreator("");
        setTagsArray([]);
        setBlogImage("");
        setReadingTime("");
        setBlogTitle("");
        setBlogDescription("");
        setBlogText("");
    }, [])

    return (localStorage.getItem('role') === UserRole.BlogAdmin ?
        <div className="font-roboto pb-[40px] bg-#FFFFFF">
            <div className="bg-#F6F6F6 h-[60px] xl:2xl:h-[70px] flex justify-center items-center">
                <h1 className="uppercase text-#333333 tracking-[0.8px] font-bold sm:text-[18px] lg:text-[20px] xl:text-[24px] 2xl:text-[36px]">
                    {t("CreateABlogPost")}
                </h1>
            </div>
            <div className="container justify-center max-w-[90%] mx-auto mt-[60px] rounded-b-[5px] tracking-[0.7px] bg-#FFFFFF h-auto space-y-5 shadow-4s">
                <div className="py-[3%] mx-auto">
                    <div className="grid grid-cols-2 px-[5%] gap-[5%]">
                        <div className="flex flex-col gap-4">
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("Creator")}</Typography>
                                <input id="creator" placeholder={t("Creator")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px]" value={creator} onChange={(event) => setCreator(event.target.value)} required></input>
                            </div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("BlogImage")}</Typography>
                                <div className="flex items-center">
                                    <label htmlFor="fileInput" className={` ${blogName !== "" ? "" : "text-#A0A0A0"} flex flex-row items-center text-[14px] overflow-hidden whitespace-nowrap text-ellipsis bg-#F6F6F6 rounded-l-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px] flex-grow cursor-pointer`} >
                                        {blogName !== "" ? blogName : t("ChooseFilesToUpload")}
                                    </label>
                                    <label htmlFor="fileInput" className={`${i18n.language === 'en' ? 'w-[25%]' : 'w-[35%]'} flex justify-center text-[14px] font-bold bg-#FFFFFF border-[1px] border-#788090-20 text-#333333 rounded-r-[5px] h-[40px] cursor-pointer`}>
                                        <input id="fileInput" type="file" accept='image/*' className="hidden" onChange={(event) => handleFileChange(event)} required />
                                        <button className="pointer-events-none">{t("BrowseFiles")}</button>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("ReadingTime")}</Typography>
                                <input id="readingTime" placeholder={t("ReadingTime")} type="number" className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px]" value={readingTime} onChange={(event) => setReadingTime(event.target.value)} required></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Typography className="text-#333333 text-[16px] font-light">{t("Tags")}</Typography>
                                <input id="tags" placeholder={t("PressEnterToAddNewTag")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pl-[15px] resize-none w-full h-[40px]" value={tag} onChange={(event) => checkTagName(event.target.value)} onKeyDown={handleKeyDownOnTags} required></input>
                            </div>
                            <div className="flex flex-wrap py-2">
                                {tagsArray.map((tag, index) => (
                                    <Chip open={openTag} onClose={() => handleTagClose(index)} animate={{ mount: { y: 0 }, unmount: { y: 30 }, }} variant="outlined" size="sm" value={tag} color="gray" className="mr-[10px] mb-[10px] rounded-[5px] lowercase text-[14px]" key={index} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="px-[3%] py-[3%]">
                        <div className="mx-auto h-[1px] w-full bg-#D9D9D9"></div>
                    </div>
                    <div className="grid grid-cols-2 px-[5%] gap-[5%]">
                        <div>
                            <Typography className="text-#333333 text-[16px] font-light">{t("BlogTitle")}</Typography>
                            <textarea id="blogTitle" placeholder={t("EnterYourBlogTitle")} className="text-[14px] placeholder:text-#A0A0A0 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pt-[10px] pb-[5px] pl-[15px] pr-[15px] resize-none w-full h-[64px]" value={title} onChange={(event) => setBlogTitle(event.target.value)} required></textarea>
                            <div className="flex flex-row justify-start">
                                <Typography className="text-#A0A0A0 text-[12px] font-light lowercase">{titleLength + "/" + blogTitleLength + " " + t("Caracters")}</Typography>
                            </div>
                        </div>
                        <div>
                            <Typography className="text-#333333 text-[16px] font-light">{t("Description")}</Typography>
                            <textarea id="description" placeholder={t("Description")} className="text-[14px] placeholder:text-#A0A0A0 text-#333333 bg-#F6F6F6 rounded-[5px] border-[1px] border-#788090-20 outline-#FF8136 pt-[10px] pb-[5px] pl-[15px] pr-[15px] resize-none w-full h-[64px]" value={description} onChange={(event) => setBlogDescription(event.target.value)} required></textarea>
                            <div className="flex flex-row justify-start">
                                <Typography className="text-#A0A0A0 text-[12px] font-light lowercase">{descriptionLength + "/" + blogDescriptionLength + " " + t("Caracters")}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center my-[3%] px-[5%]">
                        <Typography>{t("InputBlogText")}</Typography>
                        <div>
                            <ReactQuill theme="snow" className="bg-#F6F6F6" value={text} onChange={setBlogText} modules={modules} />
                        </div>
                    </div>

                    {/* <div className="px-[5%]">
                        <Typography className="text-#A0A0A0 text-[12px] font-light lowercase">{textLength + "/" + blogTextLength + " " + t("Caracters")}</Typography>
                    </div> */}

                    <div className="flex flex-col items-end pt-7 px-[5%]">
                        <Button id="activateBtn" className={`${i18n.language === 'en' ? 'w-[186px]' : 'w-[287px]'} bg-#FF8136 hover:bg-#FF6104 h-[40px] text-base font-[700] p-0 uppercase shadow-none hover:shadow-none`} onClick={handlePublishBlogClick}>
                            {t("PublishBlog")}
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={message} success={success} />
            </Dialog>
        </div > : <div>Unauthorized</div>
    );
}

export default CreateBlogPage;
