//export const apiAddress = 'http://localhost:61127/api/'; // local
// export const apiAddress = 'http://192.168.20.103:61127/api/'; //for development
export const apiAddress = 'https://webshop.bimagine.com/api/'; //for staging

//export const azureScope = "api://76ab0d33-923b-4fba-9d8f-1e67ba197353/Access_User_Data";

// export const msalConfig = {
//     auth: {
//       clientId: '4a1f1ebc-6dbd-4925-8547-10cd92897e0d' , // 'YOUR_CLIENT_ID',
//       authority: 'https://login.microsoftonline.com/common', //YOUR_TENANT_ID',
//       redirectUri: 'https://www.bimagine.com',
//     },
//   };

export const tenantID = process.env.TENANT_ID;

// export const msalConfig = {  //Development
//   auth: {
//     clientId: '1746931c-ced8-4a86-929f-0ddd3beba3be', // 'YOUR_CLIENT_ID',
//     authority: 'https://login.microsoftonline.com/common', //YOUR_TENANT_ID',
//     redirectUri: 'http://localhost:61126/',
//   },
// };
