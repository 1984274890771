import { StatusValue } from "../../app/common";

export interface CreatePortalItem {
    companyId: string;
    title: string;
    code: string;
    type: string;
    description: string;
    price: number;
    unit: string;
    currency: string;
    photo?: string | null;
    isPublic: boolean;
};

export interface EditPortalItem {
    id: string;
    companyId: string;
    title: string;
    code: string;
    type: string;
    description: string;
    price: number;
    unit: string;
    currency: string;
    photo?: string | null;
    isPublic: boolean;
};

export interface PortalItem {
    id: string;
    companyId: string;
    title: string;
    code: string;
    type: string;
    description: string;
    price: number;
    unit: string;
    currency: string;
    photo?: string | null;
    isPublic: boolean;
};

export const initialPortalItem: PortalItem = {
    id: "",
    title: "",
    code: "",
    type: "",
    description: "",
    price: 0,
    unit: "",
    currency: "",
    companyId: "",
    isPublic: true
}

export interface PortalCompany {
    id: string;
    photo: string;
    name: string;
    email: string;
    street: string;
    number: string;
    city: string;
    country: string;
    postalCode: string;
    phone: string;
    website?: string | null;
    isPublic: boolean;
};

export const initialPortalCompany: PortalCompany = {
    id: "",
    name: "",
    email: "",
    website: "",
    photo: "",
    street: "",
    number: "",
    city: "",
    country: "",
    postalCode: "",
    phone: "",
    isPublic: true
}

export interface CreateCompany {
    photo: string;
    name: string;
    email: string;
    street: string;
    number: string;
    city: string;
    country: string;
    postalCode: string;
    phone: string;
    website?: string | null;
    isPublic: boolean;
};

export interface EditCompany {
    id: string;
    photo: string;
    name: string;
    email: string;
    street: string;
    number: string;
    city: string;
    country: string;
    postalCode: string;
    phone: string;
    website?: string | null;
    isPublic: boolean;
};

export interface ProfileCompanies {
    hasPortalLicense: boolean,
    ownerCompanies: PortalCompany[],
    adminCompanies: PortalCompany[],
}

export interface PortalSliceData {
    companies: PortalCompany[],
    profileCompanies: ProfileCompanies,
    portalItemsOfCompany: PortalItem[]
    statusValues: StatusValue
}