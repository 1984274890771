import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          hello: "Hello",
          About: "About",
          Products: "Products",
          Blog: "Blog",
          FAQ: "FAQ",
          Contact: "Contact",
          PrivacyPolicy: "Privacy Policy",
          TermsAndConditions: "Terms & Conditions",
          MyProfile: "My Profile",
          Logout: "Logout",
          SignUp: "Sign up",
          Login: "Login",
          ResendActivation: "Resend activation",
          Email: "Email",
          Password: "Password",
          ShowPassword: "Show password",
          ForgotPassword: "Forgot password?",
          Send: "Send",
          Resend: "Resend",
          Register: "Register",
          ContinueWithMicrosoft: "Continue With Microsoft",
          CreateAccount: "Create account",
          DontHaveAnAccount: "Don't have an account",
          IfYouDidntGetTheEmail: "If you didn't get the email",
          ClickHere: "click here",
          Vienna: "Vienna",
          Austria: "Austria",
          AllRightsReserved: "All rights reserved",
          TroubleLoggingIn: "Trouble logging in",
          EnterYouEmail: "Enter your email and we'll send you a link to get back into your account",
          ActivateAccount: "Activate account",
          Activate: "Activate",
          BimagineShortDescription: `Streamline Your Projects with 
          Our MEP Software Solutions!`,
          Solutions: "Solutions",
          SeeMore: "See more",
          Concept: "CONCEPT",
          TheUltimateDWGAndPDFDrawingTool: "The Ultimate DWG and PDF Drawing Tool",
          TheUltimateEngineeringSolution: "The Ultimate Engineering Solution",
          ReadMore: "Read more",
          MinToRead: "Min to read",
          ContactUs: "Contact us",
          Submit: "Submit",
          Sent: "Sent",
          BackToProfile: "Back to profile",
          Name: "Name",
          Message: "Message",
          Membership: "Membership",
          InvitedBy: "Invited by",
          JoinedOn: "Joined on",
          Role: "Role",
          Licenses: "Licenses",
          ChooseLicenses: "Choose licenses",
          ChooseLicensesInfo: "Select directory to view licenses for the directory.",
          ViewLicensesBy: "View licenses by",
          FilterBy: "Filter by",
          Or: "Or",
          View: "View",
          SelectDirectory: "Select directory",
          SelectSubscription: "Select subscription",
          AssignLicense: "Assign license",
          AssignLicenseInfo: "Assign licenses for users in the selected directory.",
          SelectLicense: "Select license",
          SelectUser: "Select user",
          Assign: "Assign",
          LicenseByUser: "License by user",
          YourLicenses: "Your licenses",
          License: "License",
          Directory: "Directory",
          AssignedUser: "Assigned User",
          AssignedDirectory: "Assigned Directory",
          Editor: "Editor",
          Active: "Active",
          Deleted: "Deleted",
          Assigned: "Assigned",
          CreationTime: "Creation time",
          EditTime: "Edit time",
          ValidUntil: "Valid until",
          ValidUnit: "Valid unit",
          Subscription: "Subscription",
          LicenseType: "License type",
          Revoke: "Revoke",
          ChangePassword: "Change password",
          NewPassword: "New Password",
          ConfirmNewPassword: "Confirm New Password",
          SaveChanges: "Save Changes",
          SubscriptionId: "Subscription ID",
          ProductName: "Product name",
          CurrentPrice: "Current price",
          AvailableProductUpdates: "Available product updates",
          AvailablePriceUpdates: "Available price updates",
          StartDate: "Start Date",
          CurrentPeriodEnd: "Current period end",
          Currency: "Currency",
          Update: "Update",
          ScheduleId: "Schedule ID",
          CurrentProduct: "Current product",
          CurrentTier: "Current tier",
          CurrentNumber: "Current number",
          ScheduledProduct: "Scheduled product",
          ScheduledTier: "Scheduled tier",
          ScheduledPrice: "Scheduled price",
          ScheduledNumber: "Scheduled number",
          CreationDate: "Creation date",
          ExecutionDate: "Execution date",
          Remove: "Remove",
          UpdateSubscriptionIntervalOrTier: "Update subscription interval or tier",
          Overview: "Overview",
          Open: "Open",
          SubscriptionUpdateOverview: "Subscription update overview",
          WelcomeToYourProfile: "Welcome to your profile",
          Subscriptions: "Subscriptions",
          EditProfile: "Edit profile",
          Add: "Add",
          Set: "Set",
          AddMembership: "Add Membership",
          AddMembershipInfo: "Add Users to your directory so you can assign licenses to them.",
          SetMembershipRole: "Set Membership Role",
          SetMembershipRoleInfo: "Set Roles for the users in your directory. Admin can add users in your directory and assign licenses.",
          RemoveMembership: "Remove Membership",
          SelectRole: "Select Role",
          FirstName: "First Name",
          LastName: "Last Name",
          User: "User",
          Creator: "Creator",
          Owner: "Owner",
          DirectoryMembers: "Directory Members",
          DirectoriesYouAreIn: "Directories you are in",
          YourCurrentDirectories: "Your current directories",
          SuccessText: "Successfully changed",
          UnsuccessText: "Change was not successful",
          Continue: "Continue",
          BirthDate: "Birth date",
          PhoneNumber: "Phone number",
          Gender: "Gender",
          City: "City",
          Street: "Street",
          HouseNumber: "House number",
          PostalCode: "Postal code",
          Country: "Country",
          ProductsPageFirstParagraph: `TGA CONCEPT is a planning tool designed for the HVAC (Heating, Ventilation, and Air Conditioning) and BIM (Building Information Modeling) industry. It offers a comprehensive set of features to assist in project planning, system assignment, estimation, and visualization. The software is built using Unity3D and incorporates the Open Design Alliance (ODA) framework for file manipulation. It supports the loading and manipulation of .dwg (AutoCAD) and .pdf files, providing a versatile platform for working with architectural drawings.`,
          ProductsPageSecondParagraph: `To use TGA CONCEPT, users are required to create an account and obtain a license from bimagine.com. Once logged in, users can access the application's features through the intuitive user interface.`,
          AboutTgaConcept: "About TGA CONCEPT",
          AboutCalculationTools: "About Calculation Tools",
          AboutCalculationToolsFirstParagraph: `Calculation Tools Software equips engineers with heating load accurate calculations to enhance thermal efficiency in building designs. 
          Oversizing or under sizing a heating system can lead to inefficiency, increased costs, and discomfort for occupants.
          Calculation Tools mitigates these risks by allowing users to assess factors such as insulation, air exchange rate, building geometry, geolocation, components 
          U-value to perform accurate heating load calculations for any building.`,
          AboutCalculationToolsSecondParagraph: `Eliminate Inefficiencies and Ensure Optimal Heating System Performance with Calculation Tools.`,
          HowDoesTheProcessWithCalculationToolsWork: "How does the process with calculation tools work",
          EnterBuildingProperties: "Enter building properties",
          EnterBuildingPropertiesFirstParagraph: `In the first step, users begin by entering comprehensive building properties, ranging from fundamental features like building geometry, bottom plate thickness, and ground water depth, to more nuanced factors such as building type, materials, air tightness, and thermal considerations like thermal bridges, geolocation, and potential heating interruptions. This detailed input sets the foundation for a thorough analysis.`,
          CreateSpacesAndComponents: "Create spaces and components",
          CreateSpacesAndComponentsFirstParagraph: `Moving to the second step, the software facilitates the creation of spaces and components with user-friendly precision. Users input room dimensions and properties, allowing the automatic calculation of room volume. The process involves defining room temperature, creating components within wall construction, generating U values, entering room openings, and specifying wall orientation. This step transforms the entered data into a cohesive model, ensuring accuracy in subsequent calculations.`,
          ExportPdfFileAndGenerateReport: "Export PDF file and generate report",
          ExportPdfFileAndGenerateReportFirstParagraph: `The final step involves the efficient export of a PDF file and the generation of a comprehensive report. This report includes a detailed list of building components and openings, U values for components, building temperature, area, transmission heat transfer coefficient, transmission losses, total ventilation losses, total heat loss, losses by square and cubic meter, and a breakdown of heat loss per individual space. The software streamlines this intricate process, offering users a clear and detailed overview for informed decision-making in building design and energy management.`,
          WhatYouGetWithCalculationTools: "What You Get With Calculation Tools",
          IntuitivelyDesignedInterface: "Intuitively designed interface",
          IntuitivelyDesignedInterfaceDescription: `Calculations are displayed in a clear and easy to use format.`,
          SecureAndReliableProcess: "Secure and reliable process",
          SecureAndReliableProcessDescription: `Ensuring uninterrupted calculations for confident decision-making.`,
          StandardizedHeatLoadCalculationProcedure: "Standardized heat load calculation procedure",
          StandardizedHeatLoadCalculationProcedureDescription: `Follow industry-best practices with our streamlined, standardized procedure.`,
          AdaptiveAndEasyToUse: "Adaptive and easy to use",
          AdaptiveAndEasyToUseDescription: `Provide input, modify if needed, and read the detailed results.`,
          WhatIsCalculationToolsSoftware: "What is Calculation Tools software?",
          WhatIsCalculationToolsSoftwareAnswer: `Calculation Tools software is a powerful tool designed to accurately determine the heat load in various systems or spaces. It streamlines the process of calculating heat load by allowing users to input specific parameters and obtain precise results in Watts.`,
          WhoCanBenefitFromUsingCalculationTools: "Who can benefit from using Calculation Tools?",
          WhoCanBenefitFromUsingCalculationToolAnswer: `Calculation Tools is designed to benefit a wide range of professionals involved in building design, HVAC systems, and thermal analysis. Engineers, architects, contractors, energy consultants, and building managers can all benefit from the software's capabilities. Whether you're designing new buildings, retrofitting existing structures, or optimizing energy efficiency, Calculation Tools provides the tools and accuracy needed to make informed decisions and achieve optimal results.`,
          HowDoesCalculationToolsEnsureAccurateHeatLoadCalculations: "How does Calculation Tools ensure accurate heat load calculations?",
          HowDoesCalculationToolsEnsureAccurateHeatLoadCalculationsAnswer: `Calculation Tools ensures accurate heat load calculations through its advanced algorithms and precise methodology. The software takes into account various parameters such as building geometry, materials, insulation, air tightness, and thermal bridges to provide highly accurate results. Additionally, Calculation Tools adheres to industry standards, such as the ONORM H 7500-1 Austrian standard, ensuring reliability and consistency in calculations. With its robust features and meticulous attention to detail, Calculation Tools minimizes errors and ensures that users can trust the accuracy of their heat load calculations.`,
          IsCalculationToolsCompatibleWithDifferentBuildingTypes: "Is Calculation Tools compatible with different building types?",
          IsCalculationToolsCompatibleWithDifferentBuildingTypesAnswer: `Yes, Calculation Tools is designed to be highly versatile and compatible with various building types. Whether you're working on residential, commercial, industrial, or institutional projects, Calculation Tools can accommodate different building configurations and requirements. The software allows users to input specific parameters related to building geometry, materials, and other factors, enabling accurate heat load calculations tailored to the specific needs of each project.`,
          CanIModifyParametersOnceAProjectIsStarted: "Can I modify parameters once a project is started?",
          CanIModifyParametersOnceAProjectIsStartedAnswer: `Absolutely. Calculation Tools empowers users to modify input values or adjust settings at any stage of the project. This feature enables users to fine-tune calculations, accommodate changes in project requirements, and ensure accuracy without the hassle of recreating the entire project. With Calculation Tools, you have the freedom to refine your analysis and make informed decisions throughout the project lifecycle.`,
          WhatStandardsDoesCalculationToolsAdhereTo: "What standards does Calculation Tools adhere to?",
          WhatStandardsDoesCalculationToolsAdhereToAnswer: `Calculation Tools adheres to industry-leading standards to ensure the accuracy and reliability of its calculations. One such standard is the ONORM H 7500-1 Austrian standard, which provides comprehensive guidelines for heat load calculations. By aligning with established standards like this, Calculation Tools not only guarantees precision but also enhances compatibility and interoperability with other industry tools and practices. This adherence to standards reflects our commitment to delivering trusted and dependable solutions for our users' needs.`,
          HowDoesCalculationToolsContributeToEnergyEfficiencyInSeatingSystems: "How does Calculation Tools contribute to energy efficiency in heating systems?",
          HowDoesCalculationToolsContributeToEnergyEfficiencyInSeatingSystemsAnswer: `Calculation Tools plays a crucial role in enhancing energy efficiency within heating systems by providing accurate heat load calculations. By precisely determining the amount of heat energy required to maintain desired temperature setpoints, Calculation Tools enables engineers and designers to select appropriately sized heating equipment. This prevents oversizing or undersizing of heating systems, which can lead to inefficiencies and increased energy consumption. By facilitating informed decision-making and precise system sizing, Calculation Tools contributes to reduced energy consumption and lower operational costs in heating systems.`,
          WhatKindOfSupportAndUpdatesCanUsersExpect: "What kind of support and updates can users expect?",
          WhatKindOfSupportAndUpdatesCanUsersExpectAnswer: `Users of Calculation Tools can expect comprehensive support and regular updates to ensure a seamless experience with the software. Our dedicated support team is available to assist users with any questions, issues, or technical concerns they may encounter. Whether it's troubleshooting, guidance on using specific features, or general assistance, users can rely on prompt and helpful support from our team. In addition to ongoing support, Calculation Tools undergoes regular updates to improve functionality, address bugs, and introduce new features based on user feedback and advancements in technology.`,
          Features: "Features",
          ProjectManagement: "Project Management",
          ProjectManagementDescription: "Import drawings, define system assignments, and manage projects efficiently.",
          TemplateCreationAndUsage: "Template Creation and Usage",
          TemplateCreationAndUsageDescription: "Define standardized system asingments. Create templates with room types, systems, and subsystems.",
          RoombookManagement: "Roombook Management",
          RoombookManagementDescription: "Streamline room data and system assignments.",
          Estimation: "Estimation",
          EstimationDescription: "Perform quick and informative estimations. Calculate project costs, resource usage and more.",
          Zoning: "Zoning",
          ZoningDescription: "Accurately assign systems to individual rooms.",
          LegendGeneration: "Legend Generation",
          LegendGenerationDescription: "Create and manage legends with system names and associated rooms. With options for customization and positioning, the legend enhances flexibility and control in project presentation.",
          Visualization: "Visualization",
          VisualizationDescription: "Analyze and understand projects through 2D and 3D views. Efficient system assignment validation.",
          UserSettings: "User Settings",
          UserSettingsDescription: "It provides users with the ability to customize their experience and manage their account settings through the User Settings feature.",
          Prices: "Prices",
          monthsTitle: " months subscription",
          yearsTitle: " years subscription",
          monthTitle: " month subscription",
          yearTitle: " year subscription",
          FirstPartOfDescriptionS: "Billed once every ",
          FirstPartOfDescription: "Billed once every ",
          month: "month",
          monthCL: "Month",
          months: " months",
          monthsCL: " Months",
          year: "year",
          yearCL: "Year",
          years: " years",
          yearsCL: " Years",
          eur: "€",
          usd: "$",
          tax: "tax",
          PricePerSeat: "Price per seat",
          WhatIsTGAConcept: "What is TGA CONCEPT?",
          WhatIsTGAConceptAnswer: "TGA CONCEPT is a planning tool designed for the HVAC and BIM industry. It is built with Unity3D and utilizes the Open Design Alliance (ODA) for file manipulation. The software supports the loading of .dwg and .pdf files, as well as exporting to these formats. It provides a range of functionalities for creating and managing MEP systems, assigning systems to rooms, generating roombooks, and more. TGA CONCEPT aims to streamline the planning process and enhance collaboration in the HVAC and BIM industry.",
          HowDoICreateAnAccountAndLicense: "How do I create an account and license for TGA CONCEPT?",
          HowDoICreateAnAccountAndLicenseAnswer: "To use TGA CONCEPT, you need to create an account and obtain a license. You can create an account on the bimagine.com website, which is the official platform for TGA CONCEPT. Once you have created an account, you can log in to the application using your credentials. To obtain a license, you can follow the instructions provided on the bimagine.com platform.",
          WhatAreTemplatesInTGAConcept: "What are templates in TGA CONCEPT?",
          WhatAreTemplatesInTGAConceptAnswer: `Templates in TGA CONCEPT are predefined configurations that define the systems and subsystems used in your projects. They serve as a blueprint for assigning systems to rooms and organizing project data. Templates allow for consistent system assignment and facilitate efficient project planning in the HVAC and BIM industry.

          When creating a template, you can define room types and systems. Room types are groups of rooms with the same purpose or characteristics, such as living rooms, bathrooms, kitchens, etc. Systems represent the various HVAC and BIM systems that can be assigned to rooms.
          
          For each system, you can specify its name, color, and field type. The field type determines the type of data that can be associated with the system. There are four field types available:
          
          1. Multiple Selection: Allows you to assign one or more subsystems to the system. Subsystems are specific components or options within a system. For example, within the Heating system, you may have subsystems such as Radiator, Floor Heating, or Heat Pump.
          2. Amount (Round number): Used to assign a numerical value to represent the quantity or count of a system. This field type is typically used for systems like electrical outlets, light fixtures, or plumbing fixtures where you need to specify a specific quantity.
          3. Number (Round or decimal): Allows for the assignment of a numerical value that can include both round and decimal numbers. This field type is useful for systems that require precise measurements, such as air conditioning capacity or energy consumption.
          4. Text: Enables the assignment of textual information to a system. This field type is suitable for systems that require additional notes or descriptions.
          
          By creating templates in TGA CONCEPT, you can streamline the system assignment process, ensure consistency across projects, and enhance project management efficiency.`,
          HowDoIDefineRoomsInTGAConcept: "How do I define rooms in TGA CONCEPT?",
          HowDoIDefineRoomsInTGAConceptAnswer: `In TGA CONCEPT, you can define rooms by using the "Room by Layer" or "Room Drawing" features.

          • With "Room by Layer," you select the layer(s) that contain the room outlines (closed polylines), and TGA CONCEPT automatically identifies and defines those polylines as individual rooms.
          • With "Room Drawing," you manually draw the outlines of each room by creating closed polylines. You complete the room definition by closing the polyline, ensuring that the last point connects with the first point.
          
          Defining rooms is an essential step in the planning process as it allows for accurate system assignments and roombook generation.`,
          BuyNow: "Buy now",
          YouHaveEnteredAnInvalidEmailAddress: "You have entered an invalid email address",
          AccountIsActivated: "Account is activated",
          RequestForPasswordResetWasSentCheckYourEmail: "Request for password reset was sent. Check your email.",
          RequestForAccountCreationWasResentCheckYourEmail: "Request for account creation was resent. Check your email.",
          RequestForAccountCreationWasSentCheckYourEmail: "Request for account creation was sent. Check your email.",
          AnErrorOccurred: "An error occurred",
          InsertAllTheData: "Insert all the data",
          InsertPassword: "Insert password",
          InvalidEmail: "Invalid email",
          ValidationFailed: "Validation failed",
          PasswordsAreNotTheSame: "Passwords are not the same",
          PasswordChanged: "Password changed",
          InvalidDataInsert: "Invalid data insert",
          CheckYourEmail: "Check your email",
          Create: "Create",
          Male: "Male",
          Female: "Female",
          CreateUserData: "Create user data",
          UserDataChanged: "User data changed",
          AboutPageFirstParagraph: `BIMAGINE offers Architects, Engineers and Construction professionals modern software solutions that streamline the process of creating and executing building designs, enabling faster project completion, improving accuracy and increasing profit.`,
          AboutPageSecondParagraph: `We use our diverse knowledge gained over 20 years of experience to create high-quality software solutions. From simple conversion programs to complex solutions for technically demanding workflows, we develop solutions customized to meet industry needs. Whether it is:
            
            •	In the BIM Industry
            •	Organizational
            •	2D and 3D Visualization or
            •	Automation of Facility Management 

Simplify your processes, achieve higher quality results, reduce your costs, and save time. `,
          AboutPageTitle: `Welcome to BIMAGINE, a trademark of BIMEXPERTS.`,
          AboutPageText1: "With a mission to revolutionize the digital building industry, we bring a unique approach that connects data in a standardized way. As game changers in modern and green building technology, we are dedicated to reshaping the way buildings are envisioned and constructed.",
          AboutPageText2: "BIMEXPERTS is a leading provider of technical building planning services, specializing in the implementation of Building Information Modeling (BIM) methodology. With over 20 years of experience, our team's innovative thinking and extensive expertise have established us as a reliable partner throughout all project phases.",
          AboutPageText3: "Driven by a commitment to excellence, we operate with precision, adhering to cost-oriented and internationally recognized quality standards. Collaboration and partnership lie at the core of our values, and we always prioritize the best interests of our customers.",
          AboutPageText4: "Transparency and efficiency form the foundation of our work processes, which we continuously improve to stay at the forefront of the industry. By embracing forward-thinking technologies and investing in ongoing training, we ensure that our solutions remain cutting-edge and future-proof.",
          SeeMoreArticles: "See more articles",
          BlogText: `Welcome to our BIMAGINE Blog! Our experts cover it all – from cutting-edge technology to industry best practices.`,
          FrequentlyAskedQuestions: "Frequently Asked Questions",
          LoadMore: "Load more",
          ContactQuestion: `Can't find the answer you're looking for?`,
          ContactUsHere: `Contact us here!`,
          Success: "Success",
          Failed: "Failed",
          TransactionSuccessfull: "Your transaction was successfully executed.",
          TransactionNotExecuted: "Your transaction was not executed.",
          SomethingWentWrong: "Something went wrong.",
          PleaseTryAgain: "Please try again.",
          SolutionsText: `TGA CONCEPT is a planning tool designed for the HVAC (Heating, Ventilation, and Air Conditioning) and BIM (Building Information Modeling) industry. It offers a comprehensive set of features to assist in project planning, system assignment, estimation, and visualization. The software is built using Unity3D and incorporates the Open Design Alliance (ODA) framework for file manipulation. It supports the loading and manipulation of .dwg (AutoCAD) and .pdf files, providing a versatile platform for working with architectural drawings.`,
          Collaboration: "Collaboration",
          CollaborationText: `We build better when we work together! Trusting each other in our professions, we expect self-responsibility, rely on each other’s strengths, and appreciate everyone’s contribution and ingenuity. We work with great people and push each other to grow and develop with kindness and respect.`,
          Innovation: "Innovation",
          InnovationText: `We engage in collaborative relationships, connecting data, the purpose of overcoming boundaries and proactive problem solving, which drives us to dare to think digitally and continue being significantly better.`,
          Dedication: "Dedication",
          DedicationText: `Every day, we commit to achieving our goals. We lean on each other’s strengths, facing challenges and being focused on creating quality buildings that can “think” for themselves. Our strong dedication is valued through deep and loyal relations with our employees and customers.`,
          WhatSoftwareProductsDoYouOffer: "What software products do you offer?",
          WhatSoftwareProductsDoYouOfferAnswer: `We offer a range of software solutions tailored for the building industry, including but not limited to BIM industry, organizational, 2D-3D data visualization, as well as time management and facility management software. Contact us for more details and product solutions.`,
          WhatIsThePricingStructureForYourSoftware: "What is the pricing structure for your software?",
          WhatIsThePricingStructureForYourSoftwareAnswer: `Our software follows both monthly and yearly pricing models. You can find pricing details on our product page.`,
          WhatKindOfCustomerSupportDoYouProvide: "What kind of customer support do you provide?",
          WhatKindOfCustomerSupportDoYouProvideAnswer: "We offer email and phone support during our business hours, from 8AM to 4PM. Our team is ready to assist you with any questions or issues.",
          HowDoIObtainALicense: "How do I obtain a license?",
          HowDoIObtainALicenseAnswer: `Once you bought the license for any of our products you need to assign that license to yourself or another member of your team. If you want to assign it to another member, you also need to add that member first. All this can be done on the "My Profile" page in the "MEMBERSHIP" and "LICENSES" sections. Once the license is assigned, the product can be used. `,
          HowToEstablishB2BSingleSignOn: "How to establish B2B Single Sign On?",
          HowToEstablishB2BSingleSignOnAnswer: `If you want to have the possibility to sign in using your business account, using Single Sign On, please follow the next steps on the`,
          B2BSingleSignOn: "B2B Single Sign On",
          RegisterB2BTrust: "Register B2B Trust",
          B2BFirstParagraph: `If after the Microsoft login attempt, you face the same login page, or with some Validation error that has no explanation, something like this:`,
          B2BSecondParagraph: `If you used a business account for it, then the problem can be that no B2B Trust are registered on your Tenant.`,
          Solution: "Solution",
          RegistrationOfClientPrincipals: `•	Registration of Client principals:`,
          RegistrationOfB2BTrust: `•	Registration of B2B Trust:`,
          YourTenantIdPartOne: `Where in both cases the `,
          YourTenantIdPartTwo: ` must be replaced by your actual tenant ID.`,
          YouShouldSeeMicrosoftWindow: `You should see a Microsoft window pop-up:`,
          PickYourAccountAndLogIn: `Pick your account, and log in.`,
          PickYourAccountFirstOption: `•	If the account is an admin account when the login is done, you should be able to login to bimagine.com with that account.`,
          PickYourAccountSecondOption: `•	If it’s a regular account, you might want to talk to your IT Administrator for permission approval for the apps Central Services - Bimagine and Central Services - WebShop Backend.`,
          B2BThirdParagraphOne: `This is done in the Azure Active Directory portal, where an admin should log in.`,
          B2BThirdParagraphTwo: `The next step is to go to “Enterprise applications” -> “All applications” and find the two applications.`,
          B2BThirdParagraphThree: `Click on them, and in the security tab you can go to “Permissions” and grant admin consent for both apps:`,
          B2BFourthParagraph: `After granting the permissions, all users inside that Tenant should be able to log in to Bimagine.com.`,
          Link: "link",
          DemoLicenseAssigned: "Demo license assigned",
          Documentation: "Documentation",
          TgaDocumentationDescription: "Learn more about the functionalities and usage of TGA software.",
          ViewDocumentation: "View documentation",
          SelectaDirectoryOrSubscription: "Select a directory or subscription",
          LicenseRevoked: "License revoked",
          NoLicensesForSelectedUser: "No licenses for selected user",
          LicenseAssigned: "License assigned",
          SelectAllTheValues: "Select all the values",
          NoData: "No Data",
          SelectProduct: "Select a new product",
          SelectPrice: "Select a new price",
          MoreBlogsToRead: "More blogs to read",
          TheLatest: "The Latest",
          BackToAllBlogs: "back to all blogs",
          SingleBlogPost: "Single blog post",
          Loading: "Loading...",
          AccountCreated: "Account created",
          UserDoesNotExist: "User does not exist",
          InsertBothPasswords: "Insert both passwords",
          BackToHomepage: "Back to homepage",
          PageNotFound: "Page not found",
          Copyright: "Copyright",
          CountryMustContainAtLeast4Letters: "Country must contain at least 4 letters.",
          CountryCannotIncludeNum: "A country's name cannot include a numerical value.",
          TheDataYouInsertedIsNotValid: "The data you inserted is not valid.",
          DirectoryOf: "Directory Of: ",
          MembersOfYourDirectory: "Members of your directory",
          DownloadNow: "Download now",
          TryDemo: "Try Demo",
          Title: "Title",
          MustBeLoggedIn: "* To contact us you must be logged in.",
          PleaseInputAValidBirthdate: "Please input a valid birthdate.",
          MinimumAgeRequirement: "To use this service, the minimum age requirement is 16 years old.",
          PhoneRequirements: "Please enter the phone number using the format +123456789876 or 0123456789.",
          CreateBlog: "Create Blog",
          AllBlogPosts: "All Blog Posts",
          CreateANewBlog: "Create a new blog",
          AreYouSureYouWantToDeleteThisBlog: "Are you sure you want to delete this blog?",
          BlogDeleted: "Blog deleted",
          Delete: "Delete",
          Cancel: "Cancel",
          CreateABlogPost: "Create A Blog Post",
          ReadingTime: "Reading time",
          Description: "Description",
          PublishBlog: "Publish Blog",
          InputBlogText: "Blog Text",
          BlogImage: "Blog Image",
          ChooseFilesToUpload: "Choose files to upload",
          BlogTitle: "Blog Title",
          EnterYourBlogTitle: "Enter your blog title",
          Tags: "Tags",
          PressEnterToAddNewTag: "Press enter to add a new tag",
          Caracters: "Caracters",
          BrowseFiles: "Browse files",
          PasswordFormat: "Passwords must have at least 8 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.",
          DownloadStarted: "Download will start soon...",
          ComingSoon: "Coming soon",
          Forum: 'Forum',
          TryNow: 'Try now',
          CalculationToolsTryNowDescription: 'Ensure Optimal Heating System Performance with Calculation Tools.',
          Portal: "Portal",
          AllBrochures: "All brochures",
          CreateBrochure: "Create brochure",
          CreateANewBrochure: "Create a new brochure",
          CompanyName: "Company name",
          EnterCompanyName: "Enter company name",
          CompanyEmail: "Company email",
          EnterCompanyEmail: "Enter company email",
          UploadCompanyImage: "Upload company image",
          CompanyWebsite: "Company website",
          EnterWebsiteLink: "Enter website link",
          CreateCompany: "Create company",
          ProductTitle: "Product title",
          EnterProductTitle: "Enter product title",
          ProductType: "Product type",
          EnterProductType: "Enter product type",
          ProductPrice: "Product price",
          EnterProductPrice: "Enter product price",
          ProductDescription: "Product description",
          UploadProductImage: "Upload product image",
          SelectProductUnit: "Select product unit",
          Select: "Select",
          Meters: "Meters",
          Piece: "Piece",
          Area: "Area",
          Weight: "Weight",
          CreateANewItem: "Create a new item",
          PublishBrochure: "Publish brochure",
          CreateItems: "Create items",
          ChooseACompany: "Choose a company",
          BrowseAllBrochures: "Browse all brochures",
          NoBrochuresFound: "No brochures found.",
          NoCompaniesFound: "No companies found.",
          NoItemsFound: "No items found.",
          CompanyInforamtion: "Company information",
          CompanyLogo: "Comapny logo",
          PublishItem: "Publish item",
          ItemCreated: "Item created.",
          InsertValidEmail: "Insert valid email.",
          InsertValidPhone: "Insert valid phone.",
          CompanyCreated: "Company created.",
          CompanyUpdated: "Company updated.",
          SelectProductCurrency: "Select product currency",
          Browse: "Browse",
          Number: "Number",
          Website: "Website",
          Save: "Save",
          EnterProductCode: "Enter product code",
          ProductCode: "Product code",
          AreYouSureYouWantToDeleteThisItem: "Are you sure you want to delete this item?",
          ItemUpdated: "Item updated.",
          ItemDeleted: "Item deleted."
        },
      },
      de: {
        translation: {
          hello: "Hallo",
          About: "Über",
          Products: "Produkte",
          Blog: "Blog",
          FAQ: "FAQ",
          Contact: "Kontakt",
          PrivacyPolicy: "Datenschutz",
          TermsAndConditions: "AGB",
          MyProfile: "Mein Profil",
          Logout: "Ausloggen",
          SignUp: "Registrieren Sie sich hier.",
          Login: "Anmelden",
          ResendActivation: "Aktivierung erneut senden",
          Email: "E-Mail",
          Password: "Passwort",
          ShowPassword: "Passwort anzeigen",
          ForgotPassword: "Passwort vergessen?",
          Send: "Senden",
          Resend: "Erneut senden",
          Register: "Registrieren",
          ContinueWithMicrosoft: "Weiter mit Microsoft",
          CreateAccount: "Benutzerkonto erstellen",
          DontHaveAnAccount: "Sie habe kein Konto",
          IfYouDidntGetTheEmail: "Wenn Sie die E-Mail nicht erhalten haben",
          ClickHere: "klicken Sie hier",
          Vienna: "Wien",
          Austria: "Österreich",
          AllRightsReserved: "Alle Rechte vorbehalten",
          TroubleLoggingIn: "Probleme beim Anmelden",
          EnterYouEmail: "Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zu, um wieder auf Ihr Konto zugreifen zu können.",
          ActivateAccount: "Konto aktivieren",
          Activate: "Aktivieren",
          BimagineShortDescription: `Optimieren Sie Ihre Projekte mit unseren 
          Softwarelösungen für die Technische Gebäudeausrüstung!`,
          Solutions: "Lösungen",
          SeeMore: "Mehr erfahren",
          Concept: "CONCEPT",
          TheUltimateDWGAndPDFDrawingTool: "Das ultimative DWG und PDF Zeichentool",
          TheUltimateEngineeringSolution: "Die ultimative engineering solution",
          ReadMore: "Mehr lesen",
          MinToRead: "Min lesezeit",
          ContactUs: "Kontaktien sie uns",
          Submit: "Senden",
          Sent: "Gesendet",
          BackToProfile: "Zurück zum Profil",
          Name: "Name",
          Message: "Nachricht",
          Membership: "Mitgliedschaft",
          InvitedBy: "Eingeladen von",
          JoinedOn: "Beigetreten am",
          Role: "Rolle",
          Licenses: "Lizenzen",
          ChooseLicenses: "Lizenzen auswählen",
          ChooseLicensesInfo: "Wählen Sie das Verzeichnis aus, um die Lizenzen für das Verzeichnis anzuzeigen.",
          ViewLicensesBy: "Lizenzen anzeigen nach",
          FilterBy: "Filtern nach",
          Or: "Oder",
          // View: "Sicht",
          SelectDirectory: "Verzeichnis auswählen",
          SelectSubscription: "Abonnement auswählen",
          AssignLicense: "Lizenz zuweisen",
          AssignLicenseInfo: "Zuweisen Sie die Lizenzen für Benutzer im ausgewählten Verzeichnis",
          SelectLicense: "Lizenz auswählen",
          SelectUser: "Nutzer auswählen",
          Assign: "Zuweisen",
          LicenseByUser: "Lizenz durch benutzer",
          YourLicenses: "Ihre Lizenzens",
          License: "Lizenz",
          Directory: "Verzeichnis",
          AssignedUser: "Zugewiesener Benutzer",
          AssignedDirectory: "Zugewiesenes Verzeichnis",
          Editor: "Bearbeiter",
          Active: "Aktiv",
          Deleted: "Gelöscht",
          Assigned: "Zugewiesen",
          CreationTime: "Erstellungszeitpunkt",
          EditTime: "Zeit bearbeiten",
          ValidUntil: "Gültig bis",
          ValidUnit: "Gültige Einheit",
          Subscription: "Abonnement",
          LicenseType: "Lizenz Typ",
          Revoke: "Widerrufen",
          ChangePassword: "Passwort ändern",
          NewPassword: "Neues Passwort",
          ConfirmNewPassword: "Bestätige Neues Passwort",
          SaveChanges: "Änderungen Speichern",
          SubscriptionId: "Abonnement ID",
          ProductName: "Produktname",
          CurrentPrice: "Derzeitiger Preis",
          AvailableProductUpdates: "Verfügbare Produktaktualisierungen",
          AvailablePriceUpdates: "Verfügbare Preisaktualisierungen",
          StartDate: "Startdatum",
          CurrentPeriodEnd: "Ende der aktuellen Periode",
          Currency: "Währung",
          Update: "Aktualisieren",
          ScheduleId: "Zeitplan ID",
          CurrentProduct: "Aktuelles Produkt",
          CurrentTier: "Aktuelle Stufe",
          CurrentNumber: "Aktuelle Nummer",
          ScheduledProduct: "Geplantes Produkt",
          ScheduledTier: "Geplante Stufe",
          ScheduledPrice: "Geplanter Preis",
          ScheduledNumber: "Geplante Nummer",
          CreationDate: "Erstellungsdatum",
          ExecutionDate: "Ausführungsdatum",
          Remove: "Entfernen",
          UpdateSubscriptionIntervalOrTier: "Abonnementintervall oder -stufe aktualisieren",
          Overview: "Überblick",
          Open: "Öffnen",
          SubscriptionUpdateOverview: "Übersicht über die Abonnementaktualisierung",
          Add: "Hinzufügen",
          Set: "Einstellen",
          AddMembership: "Mitgliedschaft hinzufügen",
          AddMembershipInfo: "Fügen Sie die Benutzer zu Ihrem Verzeichnis hinzu, um ihnen Lizenzen zuweisen zu können.",
          SetMembershipRole: "Rolle für nutzer festlegen",
          SetMembershipRoleInfo: "Bestimmen Sie die Funktionen für die Benutzer in Ihrem Verzeichnis. Der Administrator kann die Benutzer zu Ihrem Verzeichnis hinzufügen und Lizenzen zuweisen.",
          RemoveMembership: "Mitgliedschaft entfernen",
          SelectRole: "Rolle Auswählen",
          FirstName: "Vorname",
          LastName: "Nachname",
          User: "User",
          Creator: "Ersteller",
          Owner: "Eigentümer",
          DirectoryMembers: "Mitglieder des Verzeichnisses",
          DirectoriesYouAreIn: "Ihre aktuellen verzeichnisse",
          YourCurrentDirectories: "Ihre aktuellen verzeichnisse",
          SuccessText: "Erfolgreich geändert",
          UnsuccessText: "Die Änderung war nicht erfolgreich",
          Continue: "Fortfahren",
          BirthDate: "Geburtsdatum",
          PhoneNumber: "Telefonnummer",
          Gender: "Geschlecht",
          City: "Stadt",
          Street: "Straße",
          HouseNumber: "Hausnummer",
          PostalCode: "Postleitzahl",
          Country: "Land",
          WelcomeToYourProfile: "Willkommen in Ihrem Profil",
          Subscriptions: "Abonnements",
          EditProfile: "Profil bearbeiten",
          ProductsPageFirstParagraph: `TGA CONCEPT ist ein Planungstool für die Technische Gebäudeausrüstung und die BIM-Branche (Building Information Modeling). Es bietet umfassende Funktionen zur Unterstützung bei der Projektplanung, Systemzuweisung, Kostenschätzung und Visualisierung. Die Software wurde mit Unity3D erstellt und integriert das Open Design Alliance (ODA)-Framework für die Dateiverarbeitung. Es unterstützt das Laden und Bearbeiten von .dwg (AutoCAD) und .pdf Dateien und bietet eine vielseitige Plattform für die Arbeit mit Konstruktionszeichnungen.`,
          ProductsPageSecondParagraph: `Um TGA CONCEPT nutzen zu können, müssen Benutzer ein Konto erstellen und eine Lizenz von bimagine.com erwerben. Nach dem Einloggen können die Nutzer über die intuitive Benutzeroberfläche auf die Funktionen der Anwendung zugreifen.`,
          AboutTgaConcept: "Über TGA CONCEPT",
          AboutCalculationTools: "About Calculation Tools",
          AboutCalculationToolsFirstParagraph: `Die Software Calculation Tools stattet Ingenieure mit genauen Heizlastberechnungen aus, um die thermische Effizienz bei Gebäudeentwürfen zu verbessern.
          Eine Über- oder Unterdimensionierung eines Heizsystems kann zu Ineffizienz, höheren Kosten und Unannehmlichkeiten für die Bewohner führen.
          Calculation Tools mindert diese Risiken, indem es Benutzern ermöglicht, Faktoren wie Isolierung, Luftwechselrate, Gebäudegeometrie, Geolokalisierung und 
          U-Wert der Komponenten zu bewerten, um genaue Heizlastberechnungen für jedes Gebäude durchzuführen.`,
          AboutCalculationToolsSecondParagraph: `Eliminieren Sie Ineffizienzen und stellen Sie mit Calculation Tools eine optimale Leistung des Heizsystems sicher.`,
          HowDoesTheProcessWithCalculationToolsWork: "Wie funktioniert der Prozess mit Calculation Tools",
          EnterBuildingProperties: "Geben Sie Gebäudeeigenschaften ein",
          EnterBuildingPropertiesFirstParagraph: `Im ersten Schritt geben Benutzer zunächst umfassende Gebäudeeigenschaften ein, die von grundlegenden Merkmalen wie Gebäudegeometrie, Bodenplattendicke und Grundwassertiefe bis hin zu differenzierteren Faktoren wie Gebäudetyp, Materialien, Luftdichtheit und thermischen Eigenschaften wie Wärmebrücken, Geolokalisierung und mögliche Heizunterbrechungen reichen. Dieser detaillierte Input bildet die Grundlage für eine gründliche Analyse.`,
          CreateSpacesAndComponents: "Räume und Komponenten erstellen",
          CreateSpacesAndComponentsFirstParagraph: `Im zweiten Schritt ermöglicht die Software die Erstellung von Räumen und Komponenten mit benutzerfreundlicher Präzision. Benutzer geben Raumabmessungen und -eigenschaften ein und ermöglichen so die automatische Berechnung des Raumvolumens. Der Prozess umfasst die Definition der Raumtemperatur, die Erstellung von Komponenten innerhalb der Wandkonstruktion, die Generierung von U-Werten, die Eingabe von Raumöffnungen und die Festlegung der Wandausrichtung. Dieser Schritt wandelt die eingegebenen Daten in ein zusammenhängendes Modell um und gewährleistet so die Genauigkeit nachfolgender Berechnungen.`,
          ExportPdfFileAndGenerateReport: "PDF-Datei exportieren und Bericht erstellen",
          ExportPdfFileAndGenerateReportFirstParagraph: `Der letzte Schritt umfasst den effizienten Export einer PDF-Datei und die Erstellung eines umfassenden Berichts. Dieser Bericht enthält eine detaillierte Liste der Gebäudekomponenten und -öffnungen, U-Werte für Komponenten, Gebäudetemperatur, Raum, Transmissionswärmeübertragungskoeffizient, Transmissionsverluste, Gesamtlüftungsverluste, Gesamtwärmeverluste, Verluste pro Quadratmeter und Kubikmeter sowie eine Aufschlüsselung der Wärmeverlust pro Einzelraum. Die Software rationalisiert diesen komplizierten Prozess und bietet Benutzern einen klaren und detaillierten Überblick für fundierte Entscheidungen bei der Gebäudeplanung und dem Energiemanagement.`,
          WhatYouGetWithCalculationTools: "Was erhalten Sie mit den Calculation Tools",
          IntuitivelyDesignedInterface: "Intuitiv gestaltete Benutzeroberfläche",
          IntuitivelyDesignedInterfaceDescription: `Berechnungen werden in einem klaren und benutzerfreundlichen Format angezeigt.`,
          SecureAndReliableProcess: "Sicherer und zuverlässiger Prozess",
          SecureAndReliableProcessDescription: `Gewährleistung unterbrechungsfreier Berechnungen für eine sichere Entscheidungsfindung.`,
          StandardizedHeatLoadCalculationProcedure: "Standardisiertes Verfahren zur Berechnung der Wärmebelastung",
          StandardizedHeatLoadCalculationProcedureDescription: `Befolgen Sie die Best Practices der Branche mit unserem optimierten, standardisierten Verfahren.`,
          AdaptiveAndEasyToUse: "Adaptiv und einfach zu bedienen",
          AdaptiveAndEasyToUseDescription: `Geben Sie Eingaben ein, ändern Sie diese bei Bedarf und lesen Sie die detaillierten Ergebnisse.`,
          WhatIsCalculationToolsSoftware: "Was ist die Software Calculation Tools?",
          WhatIsCalculationToolsSoftwareAnswer: `Die Software Calculation Tools ist ein leistungsstarkes Tool zur genauen Bestimmung der Wärmebelastung in verschiedenen Systemen oder Räumen. Es rationalisiert den Prozess der Berechnung der Wärmebelastung, indem es Benutzern die Eingabe spezifischer Parameter ermöglicht und präzise Ergebnisse in Watt erhält.`,
          WhoCanBenefitFromUsingCalculationTools: "Wer kann von der Verwendung von Calculation Tools profitieren?",
          WhoCanBenefitFromUsingCalculationToolAnswer: `Die Software Calculation Tools wurde entwickelt, um einem breiten Spektrum von Fachleuten zu helfen, die sich mit Gebäudeplanung, HVAC-Systemen und thermischer Analyse befassen. Ingenieure, Architekten, Bauunternehmer, Energieberater und Gebäudemanager können alle von den Funktionen der Software profitieren. Unabhängig davon, ob Sie neue Gebäude entwerfen, bestehende Strukturen nachrüsten oder die Energieeffizienz optimieren, bietet Calculation Tools die Tools und die Genauigkeit, die Sie benötigen, um fundierte Entscheidungen zu treffen und optimale Ergebnisse zu erzielen.`,
          HowDoesCalculationToolsEnsureAccurateHeatLoadCalculations: "Wie stellen Calculation Tools genaue Heizlastberechnungen sicher?",
          HowDoesCalculationToolsEnsureAccurateHeatLoadCalculationsAnswer: `Calculation Tools gewährleisten durch die fortschrittlichen Algorithmen und präzise Methodik genaue Wärmelastberechnungen. Die Software berücksichtigt verschiedene Parameter wie Gebäudegeometrie, Materialien, Isolierung, Luftdichtheit und Wärmebrücken, um hochpräzise Ergebnisse zu liefern. Darüber hinaus hält sich die Software Calculation Tools an Branchenstandards wie die österreichische Norm ONORM H 7500-1 und gewährleistet so Zuverlässigkeit und Konsistenz der Berechnungen. Mit seinen robusten Funktionen und der sorgfältige Beachtung zum Detail minimiert die Software Calculation Tools Fehler und stellt sicher, dass Benutzer sich auf die Genauigkeit ihrer Heizlastberechnungen verlassen können.`,
          IsCalculationToolsCompatibleWithDifferentBuildingTypes: "Sind Calculation Tools mit verschiedenen Gebäudetypen kompatibel?",
          IsCalculationToolsCompatibleWithDifferentBuildingTypesAnswer: `Ja, die Software Calculation Tools ist äußerst vielseitig und mit verschiedenen Gebäudetypen kompatibel. Unabhängig davon, ob Sie an Wohn-, Gewerbe-, Industrie- oder institutionellen Projekten arbeiten, können Calculation Tools unterschiedliche Gebäudekonfigurationen und -anforderungen berücksichtigen. Die Software ermöglicht Benutzern die Eingabe spezifischer Parameter in Bezug auf Gebäudegeometrie, Materialien und andere Faktoren und ermöglicht so genaue Berechnungen der Wärmelast, die auf die spezifischen Anforderungen jedes Projekts zugeschnitten sind.`,
          CanIModifyParametersOnceAProjectIsStarted: "Kann ich Parameter ändern, sobald ein Projekt gestartet ist?",
          CanIModifyParametersOnceAProjectIsStartedAnswer: `Absolut. Mit den Calculation Tools können Benutzer in jeder Phase des Projekts Eingabewerte ändern oder Einstellungen anpassen. Mit dieser Funktion können Benutzer Berechnungen verfeinern, die Daten an die Änderungen der Projektanforderungen anpassen und Genauigkeit sicherstellen, ohne das gesamte Projekt neu erstellen zu müssen. Mit den Calculation Tools haben Sie die Freiheit, Ihre Analyse zu verfeinern und während des gesamten Projektlebenszyklus fundierte Entscheidungen zu treffen.`,
          WhatStandardsDoesCalculationToolsAdhereTo: "An welche Standards halten sich Calculation Tools?",
          WhatStandardsDoesCalculationToolsAdhereToAnswer: `Die Software Calculation Tools hält sich an branchenführende Standards, um die Genauigkeit und Zuverlässigkeit seiner Berechnungen sicherzustellen. Ein solcher Standard ist der österreichische Standard ONORM H 7500-1, der umfassende Richtlinien für die Berechnung der Wärmebelastung bereitstellt. Durch die Ausrichtung auf etablierte Standards wie diesen garantiert die Software Calculation Tools nicht nur Präzision, sondern verbessert auch die Kompatibilität und Interoperabilität mit anderen Branchentools und -praktiken. Diese Einhaltung von Standards spiegelt unser Engagement wider, vertrauenswürdige und zuverlässige Lösungen für die Bedürfnisse unserer Benutzer bereitzustellen.`,
          HowDoesCalculationToolsContributeToEnergyEfficiencyInSeatingSystems: "Wie tragen Calculation Tools zur Energieeffizienz in Heizungsanlagen bei?",
          HowDoesCalculationToolsContributeToEnergyEfficiencyInSeatingSystemsAnswer: `Calculation Tools spielen eine entscheidende Rolle bei der Verbesserung der Energieeffizienz in Heizsystemen, indem sie genaue Berechnungen der Wärmebelastung ermöglichen. Durch die genaue Bestimmung der Menge an Wärmeenergie, die zum Aufrechterhalten der gewünschten Temperatursollwerte erforderlich ist, ermöglicht die Software Calculation Tools Ingenieuren und Designern geeignet dimensionierte Heizgeräte auszuwählen. Dies verhindert eine Über- oder Unterdimensionierung von Heizsystemen, die zu Ineffizienzen und einem erhöhten Energieverbrauch führen kann. Durch die Erleichterung einer fundierten Entscheidungsfindung und einer präzisen Systemdimensionierung tragen Calculation Tools zu einem geringeren Energieverbrauch und niedrigeren Betriebskosten in Heizsystemen bei.`,
          WhatKindOfSupportAndUpdatesCanUsersExpect: "Welche Art von Support und Updates können Benutzer erwarten?",
          WhatKindOfSupportAndUpdatesCanUsersExpectAnswer: `Benutzer von Calculation Tools können umfassenden Support und regelmäßige Updates erwarten, um ein reibungsloses Erlebnis mit der Software zu gewährleisten. Unser engagiertes Support-Team steht Benutzern bei Fragen, Problemen oder technischen Bedenken zur Seite. Ganz gleich, ob es sich um Fehlerbehebung, Anleitungen zur Verwendung bestimmter Funktionen oder allgemeine Hilfe handelt, Benutzer können sich auf die schnelle und hilfreiche Unterstützung unseres Teams verlassen. Zusätzlich zum andauernden Support werden die Calculation Tools regelmäßig aktualisiert, um die Funktionalität zu verbessern, Fehler zu beheben und neue Funktionen basierend auf Benutzerfeedback und technologischen Fortschritten einzuführen.`,
          Features: "Funktionen",
          ProjectManagement: "Projektmanagement",
          ProjectManagementDescription: "Importieren Sie Zeichnungen, definieren Sie Systemzuordnungen und verwalten Sie Projekte effizient.",
          TemplateCreationAndUsage: "Erstellung und Verwendung von Vorlagen",
          TemplateCreationAndUsageDescription: "Definieren Sie standardisierte Systemzuordnungen. Erstellen Sie Vorlagen mit Raumtypen, Systemen und Subsystemen.",
          RoombookManagement: "Raumbuchverwaltung",
          RoombookManagementDescription: "Optimieren Sie Raumdaten und Systemzuweisungen.",
          Estimation: "Schätzungen",
          EstimationDescription: "Führen Sie schnelle und aussagekräftige Schätzungen durch. Berechnen Sie Projektkosten, Ressourcenverbrauch und mehr.",
          Zoning: "Zoneneinteilung",
          ZoningDescription: "Ordnen Sie Systeme präzise den einzelnen Räumen zu.",
          LegendGeneration: "Legendenerstellung",
          LegendGenerationDescription: "Erstellen und verwalten Sie Legenden mit Systemnamen und zugehörigen Räumen. Mit Optionen zur Anpassung und Positionierung erhöht die Legende die Flexibilität und Kontrolle bei der Projektpräsentation.",
          Visualization: "Visualisierung",
          VisualizationDescription: "Analysieren und verstehen Sie Projekte anhand von 2D- und 3D-Ansichten. Effiziente Systemzuweisungsvalidierung.",
          UserSettings: "Benutzereinstellungen",
          UserSettingsDescription: "Über die Funktion Benutzereinstellungen können die Benutzer ihre Erfahrungen anpassen und ihre Kontoeinstellungen verwalten.",
          Prices: "Preise",
          monthsTitle: "-Monats-abonnement",
          yearsTitle: "-Jahres-abonnement",
          monthTitle: "-Monats-abonnement",
          yearTitle: "-Jahres-abonnement",
          FirstPartOfDescriptionS: "Abrechnung alle ",
          FirstPartOfDescription: "Abrechnung einmal pro ",
          month: "Monat",
          monthCL: "Monat",
          months: " Monate",
          monthsCL: " Monate",
          year: "Jahr",
          yearCL: "Jahr",
          years: " Jahre",
          yearsCL: " Jahre",
          eur: "€",
          usd: "$",
          tax: "steuer",
          PricePerSeat: "Pro Nutzer",
          WhatIsTGAConcept: "Was ist das TGA CONCEPT?",
          WhatIsTGAConceptAnswer: "TGA CONCEPT ist ein Planungstool für die Technische Gebäudeausrüstung und die BIM-Branche (Building Information Modeling). Es basiert auf Unity3D und nutzt die Open Design Alliance (ODA) zur Dateiverarbeitung. Die Software unterstützt das Laden von .dwg- und .pdf-Dateien sowie den Export in diese Formate. Es bietet eine Reihe von Funktionen zum Erstellen und Verwalten von HKLSE-Systemen, Zuweisen von Systemen zu Räumen, zum Erstellen von Raumbüchern und mehr. Ziel von TGA CONCEPT ist es, den Planungsprozess zu optimieren und die Zusammenarbeit in der TGA-Planung und BIM-Branche zu verbessern.",
          HowDoICreateAnAccountAndLicense: "Wie erstelle ich ein Konto und eine Lizenz für TGA CONCEPT?",
          HowDoICreateAnAccountAndLicenseAnswer: "Um TGA CONCEPT nutzen zu können, müssen Sie ein Konto erstellen und eine Lizenz erwerben. Sie können ein Konto auf der Website bimagine.com erstellen, der offiziellen Plattform für TGA CONCEPT. Nachdem Sie ein Konto erstellt haben, können Sie sich mit Ihren Zugangsdaten bei der Anwendung anmelden. Um eine Lizenz zu erhalten, können Sie den auf bimagine.com bereitgestellten Anweisungen folgen.",
          WhatAreTemplatesInTGAConcept: "Was sind Vorlagen in TGA CONCEPT?",
          WhatAreTemplatesInTGAConceptAnswer: `Vorlagen in TGA CONCEPT sind vordefinierte Konfigurationen, die die in Ihren Projekten verwendeten Systeme und Subsysteme definieren. Sie dienen als Blaupause für die Zuordnung von Systemen zu Räumen und die Organisation von Projektdaten. Vorlagen ermöglichen eine einheitliche Systemzuordnung und erleichtern eine effiziente Projektplanung in der TGA-Planung und BIM-Branche.

          Beim Erstellen einer Vorlage können Sie Raumtypen und Systeme definieren. Raumtypen sind Gruppen von Räumen mit demselben Zweck oder denselben Eigenschaften, wie z. B. Wohnzimmer, Badezimmer, Küchen usw. Systeme stellen die verschiedenen HKLS- und BIM-Systeme dar, die Räumen zugewiesen werden können.
         
          Für jedes System können Sie seinen Namen, seine Farbe und seinen Feldtyp angeben. Der Feldtyp bestimmt die Art der Daten, die dem System zugeordnet werden können. Es stehen vier Feldtypen zur Verfügung:
         
          1. Mehrfachauswahl: Ermöglicht die Zuweisung eines oder mehrerer Subsysteme zum System. Subsysteme sind spezifische Komponenten oder Optionen innerhalb eines Systems. Beispielsweise können innerhalb des Heizungssystems Subsysteme wie Heizkörper, Fußbodenheizung oder Wärmepumpe vorhanden sein.
          2. Betrag (runde Zahl): Wird verwendet, um einen numerischen Wert zuzuweisen, der die Menge oder Anzahl eines Systems darstellt. Dieser Feldtyp wird typischerweise für Systeme wie Steckdosen, Leuchten oder Sanitärarmaturen verwendet, bei denen Sie eine bestimmte Menge angeben müssen.
          3. Zahl (rund oder dezimal): Ermöglicht die Zuweisung eines numerischen Werts, der sowohl runde als auch dezimale Zahlen umfassen kann. Dieser Feldtyp ist für Systeme nützlich, die präzise Messungen erfordern, beispielsweise die Kapazität der Klimaanlage oder den Energieverbrauch.
          4. Text: Ermöglicht die Zuordnung von Textinformationen zu einem System. Dieser Feldtyp eignet sich für Systeme, die zusätzliche Notizen oder Beschreibungen benötigen.
         
          Durch die Erstellung von Vorlagen in TGA CONCEPT können Sie den Systemzuweisungsprozess optimieren, die Konsistenz über Projekte hinweg sicherstellen und die Effizienz des Projektmanagements steigern.`,
          HowDoIDefineRoomsInTGAConcept: "Wie definiere ich Räume in TGA CONCEPT?",
          HowDoIDefineRoomsInTGAConceptAnswer: `In TGA CONCEPT können Sie Räume mithilfe der Funktionen „Raum nach Ebene“ oder „Raumzeichnung“ definieren.

          • Mit „Raum nach Ebene“ wählen Sie die Ebene(n) aus, die die Raumumrisse (geschlossene Polylinien) enthalten, und TGA CONCEPT identifiziert und definiert diese Polylinien automatisch als einzelne Räume.
          • Mit „Raumzeichnung“ zeichnen Sie manuell die Umrisse jedes Raums, indem Sie geschlossene Polylinien erstellen. Sie schließen die Raumdefinition ab, indem Sie die Polylinie schließen und sicherstellen, dass der letzte Punkt mit dem ersten Punkt verbunden ist.
         
          Die Definition von Räumen ist ein wesentlicher Schritt im Planungsprozess, da sie eine genaue Systemzuordnung und Raumbucherstellung ermöglicht.`,
          BuyNow: "Jetzt kaufen",
          YouHaveEnteredAnInvalidEmailAddress: "Sie haben eine ungültige E-Mail-Adresse eingegeben",
          AccountIsActivated: "Konto ist aktiviert",
          RequestForPasswordResetWasSentCheckYourEmail: "Die Anfrage zum Zurücksetzen des Passworts wurde gesendet. Überprüfen Sie Ihre E-Mails.",
          RequestForAccountCreationWasResentCheckYourEmail: "Die Anfrage zur Kontoerstellung wurde gesendet. Überprüfen Sie Ihre E-Mails.",
          RequestForAccountCreationWasSentCheckYourEmail: "Die Anfrage zur Kontoerstellung wurde gesendet. Überprüfen Sie Ihre E-Mails.",
          AnErrorOccurred: "Ein Fehler ist aufgetreten",
          InsertAllTheData: "Geben Sie alle Daten ein",
          InsertPassword: "Passwort eingeben",
          InvalidEmail: "Ungültige E-Mail",
          ValidationFailed: "Validierung fehlgeschlagen",
          PasswordsAreNotTheSame: "Passwörter sind nicht gleich!",
          PasswordChanged: "Passwort geändert",
          InvalidDataInsert: "Ungültige Daten eingegeben",
          CheckYourEmail: "Überprüfen Sie Ihre E-Mails",
          Create: "Erstellen",
          Male: "Männlich",
          Female: "Weiblich",
          CreateUserData: "Benutzerdaten erstellen",
          UserDataChanged: "Benutzerdaten geändert",
          AboutPageFirstParagraph: `BIMAGINE bietet Architekten, Ingenieuren und Baufachleuten moderne Softwarelösungen, die den Prozess der Erstellung und Ausführung von Gebäuden rationalisieren, eine schnellere Projektabwicklung ermöglichen, die Genauigkeit verbessern und den Gewinn steigern.`,
          AboutPageSecondParagraph: `Wir nutzen unsere vielfältigen Kenntnisse, die wir in über 20 Jahren Erfahrung gesammelt haben, um hochwertige Softwarelösungen zu entwickeln. Von einfachen Konvertierungsprogrammen bis hin zu komplexen Lösungen für technisch anspruchsvolle Arbeitsabläufe entwickeln wir Lösungen, die auf die Bedürfnisse der Branche zugeschnitten sind. Sei es:
          
          •	in der BIM-Industrie, 
          •	der Arbeitsorganisation, 
          •	der 2D- und 3D-Visualisierung oder 
          •	der Automatisierung des Gebäudemanagements

Vereinfachen Sie Ihre Prozesse, erzielen Sie hochwertige Ergebnisse, reduzieren Sie Ihre Kosten und sparen Sie Zeit.`,
          AboutPageTitle: `Willkommen bei BIMAGINE, einer Marke von BIMEXPERTS.`,
          AboutPageText1: "Mit der Mission, die digitale Baubranche zu revolutionieren, verfolgen wir einen einzigartigen Ansatz, der Daten auf standardisierte Weise verknüpft. Als Wegbereiter moderner und umweltfreundlicher Gebäudetechnologie sind wir entschlossen, die Art und Weise, wie Gebäude konzipiert und gebaut werden, neu zu gestalten.",
          AboutPageText2: "BIMEXPERTS ist ein führender Anbieter Technischer Gebäudeausrüstungsplanung (TGA-Planung), der sich auf die Implementierung der Building Information Modeling (BIM)-Methodik spezialisiert hat. Mit über 20 Jahren Erfahrung, innovativem Denken und umfassendem Fachwissen unseres Teams haben wir uns als verlässlicher Partner in allen Projektphasen etabliert.",
          AboutPageText3: "Angetrieben von der Verpflichtung zur Exzellenz arbeiten wir mit Präzision und halten uns an kostenorientierte und international anerkannte Qualitätsstandards. Zusammenarbeit und Partnerschaft stehen im Mittelpunkt  unserer Werte, und für uns steht stets das Wohl unserer Kunden im Vordergrund.",
          AboutPageText4: "Transparenz und Effizienz bilden die Grundlage unserer Arbeitsprozesse, die wir kontinuierlich verbessern, um an vorderster Front der Branche zu bleiben. Durch die Nutzung zukunftsorientierter Technologien und Investitionen in kontinuierliche Schulungen stellen wir sicher, dass unsere Lösungen auf dem neuesten Stand und zukunftssicher bleiben.",
          SeeMoreArticles: "Zu den artikeln",
          BlogText: `Willkommen zu unserem BIMAGINE Blog! 
          Unsere Experten berichten über alles – von modernster Technologie bis hin zu bewährten Praktiken der Branche.`,
          FrequentlyAskedQuestions: "FAQ – Häufig gestellte Fragen",
          LoadMore: "Mehr laden",
          ContactQuestion: `SIE FINDEN NICHT DIE ANTWORT, DIE SIE SUCHEN?`,
          ContactUsHere: `Kontaktieren Sie uns hier!`,
          Success: "Erfolgreich",
          Failed: "Fehlgeschlagen",
          TransactionSuccessfull: "Ihre Transaktion wurde erfolgreich ausgeführt.",
          TransactionNotExecuted: "Ihre Transaktion wurde nicht ausgeführt.",
          SomethingWentWrong: "Etwas ist schiefgelaufen.",
          PleaseTryAgain: "Bitte versuchen Sie es erneut.",
          SolutionsText: `TGA CONCEPT ist ein Planungstool für die Technische Gebäudeausrüstung und die BIM-Branche (Building Information Modeling). Es bietet umfassende Funktionen zur Unterstützung bei der Projektplanung, Systemzuweisung, Kostenschätzung und Visualisierung. Die Software wurde mit Unity3D erstellt und integriert das Open Design Alliance (ODA)-Framework für die Dateiverarbeitung. Es unterstützt das Laden und Bearbeiten von .dwg- (AutoCAD) und .pdf-Dateien und bietet eine vielseitige Plattform für die Arbeit mit Konstruktionszeichnungen.`,
          Collaboration: "Zusammenarbeit",
          CollaborationText: `Wir bauen besser, wenn wir zusammenarbeiten! Wir vertrauen einander in unseren Aufgaben, erwarten Eigenverantwortung, verlassen uns auf die Stärken der anderen und schätzen den Beitrag und den Einfallsreichtum jedes Einzelnen. Wir arbeiten mit großartigen Menschen zusammen und treiben uns gegenseitig an, mit Freundlichkeit und Respekt zu wachsen und uns zu entwickeln.`,
          Innovation: "Innovation",
          InnovationText: `Wir gehen kollaborative Beziehungen ein und verbinden Daten, um Grenzen zu überwinden und proaktive Problemlösungen zu finden. Das treibt uns an, digital zu denken und uns kontinuierlich zu verbessern.`,
          Dedication: "Engagement",
          DedicationText: `Jeden Tag setzen wir uns dafür ein, unsere Ziele zu erreichen. Wir stützen uns auf die Stärken des anderen, stellen uns den Herausforderungen und konzentrieren uns auf die Schaffung von Qualitätsgebäuden, die für sich selbst "denken" können. Unser starkes Engagement wird durch tiefe und loyale Beziehungen zu unseren Mitarbeitern und Kunden gewürdigt.`,
          WhatSoftwareProductsDoYouOffer: "Welche Softwareprodukte bieten Sie an?",
          WhatSoftwareProductsDoYouOfferAnswer: `Wir bieten eine Reihe von kundenspezifischen Softwarelösungen für die Baubranche an, einschließlich, aber nicht beschränkt auf die BIM-Branche, Organisationssoftware, 2D-3D-Datenvisualisierung sowie ERP und Facility-Management-Software. Kontaktieren Sie uns für weitere Details und Produktlösungen.`,
          WhatIsThePricingStructureForYourSoftware: "Wie ist der Preis für Ihre Software?",
          WhatIsThePricingStructureForYourSoftwareAnswer: `Die Preise für unsere Software basieren auf einem monatlichen und jährlichen Preismodell. Detaillierte Preisinformationen finden Sie auf unserer Produktseite.`,
          WhatKindOfCustomerSupportDoYouProvide: "Welche Art von Kundensupport bieten Sie an?",
          WhatKindOfCustomerSupportDoYouProvideAnswer: "Während unserer Geschäftszeiten, zwischen 08:00 und 16:00 Uhr, bieten wir E-Mail- und Telefonsupport an. Unser Team steht Ihnen bei Fragen und Problemen gerne zur Verfügung.",
          HowDoIObtainALicense: "Wie erhalte ich eine Lizenz für das Produkt?",
          HowDoIObtainALicenseAnswer: `Sobald Sie die Lizenz für eines unserer Produkte erworben haben, müssen Sie diese Lizenz entweder sich selbst oder einem anderen Mitglied Ihres Teams zuweisen. Wenn Sie es einem anderen Mitglied zuweisen möchten, müssen Sie dieses Mitglied zuerst hinzufügen. All dies kann auf der Seite „Mein Profil“ in den Abschnitten „MITGLIEDSCHAFT“ und „LIZENZEN“ erledigt werden. Sobald die Lizenz zugewiesen ist, kann das Produkt verwendet werden.`,
          HowToEstablishB2BSingleSignOn: "Wie soll man B2B Single Sign On?",
          HowToEstablishB2BSingleSignOnAnswer: `Im Falle, dass Sie die Möglichkeit haben möchten, sich mit Ihrem Geschäftskonto per Single Sign On/ einmaliges Anmelden anzumelden, folgen Sie bitte die nächsten Schritte unter diesem`,
          B2BSingleSignOn: "B2B Single Sign On",
          RegisterB2BTrust: "Registrieren Sie B2B Vertrauen",
          B2BFirstParagraph: `Im Falle, dass nach dem Microsoft-Anmeldeversuch dieselbe Anmeldeseite angezeigt wird oder ein Validierungsfehler vorliegt, für den es keine Erklärung gibt, etwa so:`,
          B2BSecondParagraph: `Wenn Sie dafür ein Geschäftskonto verwendet haben, kann das Problem darin liegen, dass kein B2B Vertrauen in Ihrem Verzeichnis registriert ist.`,
          Solution: "Lösung",
          RegistrationOfClientPrincipals: `•	Registrierung von Auftraggebern:`,
          RegistrationOfB2BTrust: `•	Registrierung von B2B Vertrauen:`,
          YourTenantIdPartOne: `In beiden Fällen muss die `,
          YourTenantIdPartTwo: ` durch Ihre tatsächliche Verzeichnis-ID ersetzt werden.`,
          YouShouldSeeMicrosoftWindow: `Sie sollten ein Microsoft Popup-Fenster sehen:`,
          PickYourAccountAndLogIn: `Wählen Sie Ihr Konto aus und melden Sie sich an.`,
          PickYourAccountFirstOption: `•	Wenn es sich zum Zeitpunkt der Anmeldung um ein Admin-Konto handelt, sollten Sie sich mit diesem Konto bei bimagine.com anmelden können.`,
          PickYourAccountSecondOption: `•	Wenn es sich um ein reguläres Konto handelt, wenden Sie sich an Ihren IT-Administrator, um die Genehmigung für die Apps Central Services - Bimagine und Central Services - WebShop Backend zu erhalten.`,
          B2BThirdParagraphOne: `Dies geschieht im Azure Active Directory-Portal, wo sich der Administrator anmelden sollte.`,
          B2BThirdParagraphTwo: `Der nächste Schritt besteht darin, sie sollen „Unternehmens-Apps“ -> „Alle Apps“ auswählen und die beiden Apps finden.s`,
          B2BThirdParagraphThree: `Klicken Sie darauf und auf dem Tab „Sicherheit“ können Sie „ Genehmigungen “ auswählen und die Administratoreinwilligung für beide Apps erteilen:`,
          B2BFourthParagraph: `Nach der Erteilung der Berechtigungen sollten sich alle Benutzer innerhalb dieses Mandanten bei Bimagine.com anmelden können.`,
          Link: "Link",
          DemoLicenseAssigned: "Demo-Lizenz zugewiesen",
          Documentation: "Dokumentation",
          TgaDocumentationDescription: "Erfahren Sie mehr über die Funktionalitäten und Nutzung der TGA-Software.",
          ViewDocumentation: "Dokumentation anzeigen",
          SelectaDirectoryOrSubscription: "Wählen Sie ein Verzeichnis oder Abonnement aus",
          LicenseRevoked: "Lizenz widerrufen",
          NoLicensesForSelectedUser: "Keine Lizenzen für den ausgewählten Benutzer",
          LicenseAssigned: "Lizenz zugewiesen",
          SelectAllTheValues: "Wählen Sie alle Werte aus",
          NoData: "Keine Daten",
          SelectProduct: "Produkt auswählen",
          SelectPrice: "Preis auswählen",
          MoreBlogsToRead: "Weitere Blogs zum Lesen",
          TheLatest: "Das Neueste",
          BackToAllBlogs: "zurück zu allen Blogs",
          SingleBlogPost: "Einzelner Blogbeitrag",
          Loading: "Wird geladen...",
          AccountCreated: "Account ist erstellt",
          UserDoesNotExist: "Benutzer existiert nicht",
          InsertBothPasswords: "Geben Sie beide Passwörter ein",
          BackToHomepage: "Zurück zur Startseite",
          PageNotFound: "Seite nicht gefunden",
          Copyright: "Copyright",
          CountryMustContainAtLeast4Letters: 'Das Feld "Land" muss mindestens 4 Buchstaben enthalten.',
          CountryCannotIncludeNum: "Der Name eines Landes darf keinen numerischen Wert enthalten.",
          TheDataYouInsertedIsNotValid: "Die von Ihnen eingegebenen Daten sind ungültig.",
          DirectoryOf: "Verzeichnis von: ",
          MembersOfYourDirectory: "Mitglieder Ihres Verzeichnisses",
          DownloadNow: "Jetzt herunterladen",
          TryDemo: "Demo ausprobieren",
          Title: "Titel",
          MustBeLoggedIn: "* Sie müssen angemeldet sein, um mit uns Kontakt aufzunehmen.",
          PleaseInputAValidBirthdate: "Bitte geben Sie ein gültiges Geburtsdatum ein.",
          MinimumAgeRequirement: "Um diesen Service nutzen zu können, beträgt das Mindestalter 16 Jahre.",
          PhoneRequirements: "Bitte geben Sie die Telefonnummer im Format +123456789876 oder 0123456789 ein.",
          CreateBlog: "Blog Erstellen",
          AllBlogPosts: "Alle Blog-Beiträge",
          CreateANewBlog: "Erstelle einen neuen Blog",
          AreYouSureYouWantToDeleteThisBlog: "Sind Sie sicher, dass Sie diesen Blog löschen möchten?",
          BlogDeleted: "Blog gelöscht",
          Delete: "Löschen",
          Cancel: "Stornieren",
          CreateABlogPost: "Erstellen Sie einen Blogbeitrag",
          ReadingTime: "Lesezeit",
          Description: "Beschreibung",
          PublishBlog: "Artikel veröffentlichen",
          InputBlogText: "Blog Text",
          BlogImage: "Blogbild",
          ChooseFilesToUpload: "Wähle Dateien zum Hochladen",
          BlogTitle: "Blog Titel",
          EnterYourBlogTitle: "Geben Sie Ihren Blogtitel ein",
          Tags: "Stichworte",
          PressEnterToAddNewTag: "Drück Enter, um ein neues Tag hinzuzufügen",
          Caracters: "Charaktere",
          BrowseFiles: "Dateien durchsuchen",
          PasswordFormat: "Passwörter müssen mindestens 8 Zeichen lang sein mindestens zwei der folgenden Elemente enthalten: Großbuchstaben Buchstaben, Kleinbuchstaben, Zahlen und Symbole.",
          DownloadStarted: "Download startet in Kürze...",
          ComingSoon: "Bald erhältlich",
          Forum: 'Forum',
          TryNow: `Versuch's jetzt`,
          CalculationToolsTryNowDescription: 'Stellen Sie mit Berechnungstools eine optimale Leistung Ihres Heizsystems sicher.',
          Portal: "Portal",
          AllBrochures: "Alle Broschüren",
          CreateBrochure: "Broschüre erstellen",
          CreateANewBrochure: "Erstelle eine neue Broschüre",
          CompanyName: "Firmenname",
          EnterCompanyName: "Firmennamen eingeben",
          CompanyEmail: "Firmen-E-Mail",
          EnterCompanyEmail: "Firmen-E-Mail eingeben",
          UploadCompanyImage: "Firmenbild hochladen",
          CompanyWebsite: "Firmenwebseite",
          EnterWebsiteLink: "Webseitenlink eingeben",
          CreateCompany: "Firma erstellen",
          ProductTitle: "Produkttitel",
          EnterProductTitle: "Produkttitel eingeben",
          ProductType: "Produkttyp",
          EnterProductType: "Produkttyp eingeben",
          ProductPrice: "Produktpreis",
          EnterProductPrice: "Produktpreis eingeben",
          ProductDescription: "Produktbeschreibung",
          UploadProductImage: "Produktbild hochladen",
          SelectProductUnit: "Produkteinheit auswählen",
          Select: "Auswählen",
          Meters: "Meter",
          Piece: "Stück",
          Area: "Fläche",
          Weight: "Gewicht",
          CreateANewItem: "Neues Element erstellen",
          PublishBrochure: "Broschüre veröffentlichen",
          CreateItems: "Elemente erstellen",
          ChooseACompany: "Wählen Sie ein Unternehmen",
          BrowseAllBrochures: "Alle Broschüren durchsuchens",
          NoBrochuresFound: "Keine Broschüren gefunden.",
          NoCompaniesFound: "Keine Unternehmen gefunden.",
          NoItemsFound: "Keine Einträge gefunden.",
          CompanyInforamtion: "Informationen zum Konzern",
          CompanyLogo: "Firmenlogo",
          PublishItem: "Artikel veröffentlichen",
          ItemCreated: "Artikel erstellt.",
          InsertValidEmail: "Gültige E-Mail einfügen",
          InsertValidPhone: "Gültige Telefonnummer einfügen",
          CompanyCreated: "Unternehmen erstellt.",
          CompanyUpdated: "Unternehmen aktualisiert.",
          SelectProductCurrency: "Produktwährung auswählen",
          Browse: "Durchsuchen",
          Number: "Nummer",
          Website: "Webseite",
          Save: "Speichern",
          EnterProductCode: "Produktcode eingeben",
          ProductCode: "Produktcode",
          AreYouSureYouWantToDeleteThisItem: "Möchten Sie diesen Artikel wirklich löschen?",
          ItemUpdated: "Artikel aktualisiert.",
          ItemDeleted: "Element gelöscht."
        },
      },
      srb: {
        translation: {
          hello: "Zdravo",
        },
      },
    },
  });

export default i18n;
