// companyinfo

import { Typography, Dialog, Input, Button, Checkbox } from "@material-tailwind/react";
import { ResponsiveWidthProfile } from "../../../app/common";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import SuccessCard from "../../CommonPages/successCard";
import { ChangeEvent, useEffect, useState } from "react";
import { initialPortalCompany, PortalCompany } from "../PortalModel";
import { GetProfileCompanies, PostCreatePortalCompany, PutEditPortalCompany, resetStatusCode } from "../PortalSlice";

export function CompanyInfo() {
    const { t, i18n } = useTranslation();
    const portalSliceData = useSelector((state: RootState) => state.PortalSlice.portalSliceData);
    const dispatch = useDispatch<AppDispatch>();
    const [isCreateForm, setIsCreateForm] = useState(portalSliceData.profileCompanies.ownerCompanies?.length === 0 ? true : false);
    const [company, setCompany] = useState<PortalCompany>(!isCreateForm ? portalSliceData.profileCompanies.ownerCompanies[0] : initialPortalCompany)
    const [logoImageName, setLogoImageName] = useState("");

    //Dialog:
    const [messsage, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const [errors, setErrors] = useState<{ email: boolean; phone: boolean; }>({ email: false, phone: false, });

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phone: string): boolean => {
        const phoneRegex = /^[+\d][\d]*$/;
        return phoneRegex.test(phone);
    };

    function handleLogoFileChange(e: ChangeEvent<HTMLInputElement>): void {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];

            setLogoImageName(selectedFile.name);
            getBase64(selectedFile)
                .then(result => {
                    setCompany((prevCompany) => ({ ...prevCompany, photo: result }))
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    useEffect(() => {
        if (portalSliceData.profileCompanies.ownerCompanies[0]) {
            setCompany(portalSliceData.profileCompanies.ownerCompanies[0])
        }
    }, [portalSliceData.profileCompanies.ownerCompanies])

    const getBase64 = (file: File): Promise<string> => {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result as string);
            };
        });
    };

    function onCompanySaveClick(): void {
        const emailValid = validateEmail(company.email);
        const phoneValid = validatePhoneNumber(company.phone);

        if (!emailValid) {
            setSuccess(false);
            setMessage(t("InsertValidEmail"))
            handleOpenDialog();
            return;
        }

        if (!phoneValid) {
            setSuccess(false);
            setMessage(t("InsertValidPhone"))
            handleOpenDialog();
            return;
        }

        if (Object.entries(company).filter(([key]) => key !== "postalCode" && key !== "website" && key !== "id").some(([_, value]) => value === "")) {
            setSuccess(false);
            setMessage(t("InsertAllTheData"))
            handleOpenDialog();
            return;
        }

        if (isCreateForm) {
            dispatch(PostCreatePortalCompany(company))
        }
        else {
            dispatch(PutEditPortalCompany(company))
        }

    }

    useEffect(() => {
        if (portalSliceData.statusValues.statusCode === '200') {
            setSuccess(true);
            dispatch(GetProfileCompanies())
            if (isCreateForm) {
                setMessage(t("CompanyCreated"));
                setIsCreateForm(false)
            }
            else {
                setMessage(t("CompanyUpdated"));
            }
            handleOpenDialog();
        }
        else if (portalSliceData.statusValues.statusCode !== '') {
            setSuccess(false);
            setMessage(portalSliceData.statusValues.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
        dispatch(resetStatusCode());
    }, [portalSliceData.statusValues.statusCode])

    return (
        <div id="companyInfo" className={"lg:scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[90px] rounded-b-[5px] bg-#FFFFFF h-auto space-y-5 shadow-4s pb-10 " + ResponsiveWidthProfile}>
            <div className="2xl:h-[30px] bg-#FF6104-20 rounded-t-[5px]">
                <Typography variant="lead" className="uppercase font-semibold px-10">
                    {t('CompanyInforamtion')}
                </Typography>
            </div>
            {/* Inside CompanyInformation*/}
            <div className="px-10 gap-5 grid grid-cols-3 xl:grid-cols-10 xl:max-w-[1280px]">
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                        <Typography variant="paragraph" className="uppercase font-semibold">{t("CompanyLogo")}</Typography>
                        <div className="flex items-center">
                            <label htmlFor="logoFileInput" className={` ${logoImageName !== "" ? "" : "text-blue-gray-200"} flex flex-row items-center text-[14px] overflow-hidden whitespace-nowrap text-ellipsis rounded-l-[5px] border-[1px] border-blue-gray-200 border-r-0 outline-#FF8136 pl-[15px] resize-none w-full h-[40px] flex-grow cursor-pointer`} >
                                {logoImageName !== "" ? logoImageName : t("ChooseFilesToUpload")}
                            </label>
                            <label htmlFor="logoFileInput" className={`${i18n.language === 'en' ? 'w-[35%]' : 'w-[45%]'} flex justify-center text-[14px] font-bold bg-#FF8136 border-none text-#333333 rounded-r-[5px] h-[40px] cursor-pointer`}>
                                <input id="logoFileInput" type="file" accept='image/*' className="hidden" onChange={(event) => handleLogoFileChange(event)} required />
                                <button className="pointer-events-none uppercase text-#FFFFFF">{t("Browse")}</button>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('CompanyName')}</Typography>
                    <Input id="CompanyName" type="text" color="orange" label={t("CompanyName")} value={company.name} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, name: e.target.value }))}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('Email')}</Typography>
                    <Input id="email" type="email" color="orange" label={t("Email")} value={company.email} error={errors.email} className="rounded"
                        onChange={(e) => {
                            const email = e.target.value;
                            setCompany((prevCompany) => ({ ...prevCompany, email }));
                            setErrors((prevErrors) => ({ ...prevErrors, email: !validateEmail(email) }));
                        }}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('Street')}</Typography>
                    <Input id="street" type="text" color="orange" label={t("Street")} value={company.street} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, street: e.target.value }))}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('Number')}</Typography>
                    <Input id="number" type="text" color="orange" label={t("Number")} value={company.number} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, number: e.target.value }))}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('City')}</Typography>
                    <Input id="city" type="text" color="orange" label={t("City")} value={company.city} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, city: e.target.value }))}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('Country')}</Typography>
                    <Input id="country" type="text" color="orange" label={t("Country")} value={company.country} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, country: e.target.value }))}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('PostalCode')}</Typography>
                    <Input id="postalCode" type="text" color="orange" label={t("PostalCode")} value={company.postalCode} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, postalCode: e.target.value }))}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('PhoneNumber')}</Typography>
                    <Input id="phone" type="text" color="orange" label={t("PhoneNumber")} error={errors.phone} value={company.phone} className="rounded"
                        onChange={(e) => {
                            const phone = e.target.value;
                            setCompany((prevCompany) => ({ ...prevCompany, phone }));
                            setErrors((prevErrors) => ({ ...prevErrors, phone: !validatePhoneNumber(phone) }));
                        }}>
                    </Input>
                </div>
                <div className="xl:max-w-[320px] gap-3 flex flex-col col-span-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('Website')}</Typography>
                    <Input id="website" type="text" color="orange" label={t("Website")} value={company.website ?? undefined} className="rounded"
                        onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, website: e.target.value }))}>
                    </Input>
                </div>
                <div className="mb-[2px] mt-auto" >
                    <Checkbox label={"Public"} color="orange" checked={company.isPublic} onChange={(e) => setCompany((prevCompany) => ({ ...prevCompany, isPublic: e.target.checked }))} />
                </div>
                <div className="mb-0 mt-auto ">
                    <Button id="SaveCompanyClick" onClick={onCompanySaveClick} color="deep-orange" className="bg-#FF8136 shadow-none float-left hover:bg-#FF6104 hover:shadow-none">{t('Save')}</Button>
                </div>
            </div>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div >
    )
}